html .overlay {
    z-index: 25;
}

.overlay--gallery {
    .overlay__container {
        top: 0;
        left: 0;
        max-width: none;
        height: 100%;
        transform: none;
        box-shadow: none;

        > .overlay__close {
            display: none;
        }
    }

    .overlay__bg {
        background-color: $c-white;
    }

        .overlay__close {
            top: 11px;
            right: 10px;
            padding: 10px;
            background: transparent;
            border: none;
            z-index: 1;

            svg {
                width: 20px;
                height: 20px;
                stroke: $c-black;
            }
        }

        .overlay__content {
            min-height: 100vh;
            width: 100%;
        }

        .gallery {
            height: 100%;
            width: 100%;
            position: absolute;

            .body--admin & {
                padding-top: 44px;
            }
            .body--debug & {
                padding-top: 20px;
                @include breakpoint(tablet) {
                    padding-top: 0;
                }
            }
            .body--admin.body--debug & {
                padding-top: 64px;
                @include breakpoint(tablet) {
                    padding-top: 44px;
                }
            }

            &.gallery--black {
                background-color: $c-black;
            }
        }
}
