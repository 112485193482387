.block--projects-list {
    .block__title {
        @include font(25px, 33px);
        @include mediumText;

        margin-bottom: 50px;

        @include breakpoint(tablet) {
            @include font(17px, 28px);

            margin-bottom: 38px;
        }
    }
}
