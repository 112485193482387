/// Define the simple row configuration for a grid child (including -ms prefix)
///
/// @param {Number} $row The row
/// @author Joe Adcock <joe@d3r.com>

@mixin gridRow($row, $span: 1) {
    -ms-grid-row: $row;
    -ms-grid-row-span: $span;
    grid-row: $row / span $span;
}
