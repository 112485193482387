.block--banner {
    @include lightondark;

    position: relative;
    color: $c-white;

    .block__image {
        @include aspect(9 / 4);

        background: $c-black;
        position: relative;

        @include breakpoint(phablet) {
            @include aspect(5 / 6);
        }

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
            opacity: 0.75;
        }
    }

    .block__image--tint-dark {
        &:after {
            content: '';
            background-color: transparentize($c-black, .7);
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            visibility: visible;
            height: auto;
        }
    }


    .block__content {
        position: absolute;
        top: 84px;
        left: 50%;
        max-width: 393px;
        text-align: center;
        transform: translateX(-50%);
        z-index: 2;

        @include breakpoint(tablet) {
            top: 54px;
            max-width: 500px;
            left: 30px;
            right: 30px;
            transform: none;
            margin: 0 auto;
        }
    }

        .block__title {
            @include block-title;

            @include breakpoint(tablet) {
                @include font(21px, 30px);
                @include margin(bottom, 14px);

                letter-spacing: 0.4px;
            }
        }

        .block__text {
            @include font(16px, 26px);
            @include margin(bottom, 14px);

            @include breakpoint(tablet) {
                @include font(15px, 26px);
                @include margin(bottom, 10px);
            }
        }
}
