.feature {}

    .feature--full {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: inherit;

        @include breakpoint(mobile) {
            display: block;
        }
    }

        .feature__link {
            color: $c-black;
            text-decoration: none;
        }

            .feature__image {
                @include aspect(9 / 4);
                @include margin(bottom, 30px);

                width: 3 * (80px + $grid-gutter-h);
                margin-right: auto;
                margin-left: auto;

                @include breakpoint(tablet) {
                    @include margin(bottom, 10px);
                }

                .feature--full & {
                    @include margin(right, 40px + $grid-gutter-h);

                    flex: 0 0 auto;
                    margin-bottom: 0;

                    @include breakpoint(tablet) {
                        margin-right: 10px;
                        margin-left: auto;
                    }

                    @include breakpoint(mobile) {
                        @include margin(bottom, 15px);
                        width: auto;
                        margin-right: 0;
                    }
                }
            }

            .feature__content {
                max-width: 280px;
                margin-right: auto;
                margin-left: auto;
                text-align: center;

                @include breakpoint(tablet) {
                    max-width: 310px;
                }

                .feature--full & {
                    max-width: none;
                    text-align: left;

                    @include breakpoint(tablet) {
                        text-align: center;

                        margin-right: auto;
                        margin-left: 10px;
                    }

                    @include breakpoint(mobile) {
                        max-width: none;
                        margin-left: 0;
                    }
                }
            }

                .feature__title {
                    @include font(18px, 30px);
                    @include mediumText;
                    @include margin(bottom, 3px);

                    @include breakpoint(tablet) {
                        @include font(17px, 30px);
                        @include margin(bottom, 7px);

                        letter-spacing: 0.4px;
                        text-align: left;
                    }

                    .feature--full & {
                        @include margin(bottom, 7px);
                    }

                    .feature__link:hover & {
                        text-decoration: underline;
                    }
                }

                .feature__text {
                    @include font(16px, 26px);
                    @include margin(bottom, 20px);

                    @include breakpoint(tablet) {
                        @include font(15px, 26px);
                        @include margin(bottom, 13px);

                        text-align: left;
                    }

                    .feature--full & {
                        @include font(14px, 24px);

                        @include breakpoint(tablet) {
                            @include font(15px, 26px);
                        }
                    }
                }

                .feature__date {
                    @include font(14px, 21px);
                    @include lightText;

                    letter-spacing: 0.6px;
                }
