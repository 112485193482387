.carousel {
    &.carousel--active {
        overflow: hidden;
    }
}

.carousel--quotes {
    max-width: 1600px;
    margin: 0 auto;
    position: relative;

    @include breakpoint(tablet) {
        overflow: hidden;
    }
}

    .carousel__header {
        position: relative;
        margin-right: $site-gutter;
        margin-left: $site-gutter;
        padding-right: 70px + 10px;

        .carousel--quotes & {
            position: static;
        }

        @include breakpoint(full) {
            max-width: $site-max-width;
            margin-left: auto;
            margin-right: auto;
            padding-left: $site-gutter;
            padding-right: $site-gutter;
        }

        @include breakpoint(tablet) {
            margin-right: $site-gutter--mobile;
            margin-left: $site-gutter--mobile;
        }

        @include breakpoint(phablet) {
            padding-right: 0;
        }

        @each $name, $breakpoint in $sizes {
            [data-carousel-activate="#{$name}"] & {
                display: none;

                @include breakpoint($breakpoint) {
                    display: block;
                }
            }
        }
    }

    .carousel__header--has-intro {
    }

    .carousel__header--mobile-center {
        @include breakpoint(tablet) {
            text-align: center;
        }
    }

        .carousel__title {
            @include font(25px, 33px);
            @include mediumText;
            @include margin(bottom, 18px);

            @include breakpoint(tablet) {
                @include font(21px, 30px);
                @include margin(bottom, 18px);

                letter-spacing: 0.4px;
            }
        }

        .carousel__intro {
            @include font(16px, 26px);
            @include margin(bottom, 30px);

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: -8px;

            @include breakpoint(phablet) {
                @include margin(bottom, 20px);
                display: block;
                margin-top: -9px;
            }

            .cta {
                transform: translateY(3px);
            }
        }

            .carousel__text {
                @include margin(right, 15px);

                @include breakpoint(phablet) {
                    @include margin(bottom, 6px);

                    margin-right: 0;
                }
            }

        .carousel__controls {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @include breakpoint(full) {
                right: 80px;
            }

            @include breakpoint(phablet) {
                display: none;
            }

            .carousel--show-controls & {

                @include breakpoint(phablet) {
                    display: flex;
                }
            }

            .carousel__intro + & {
                top: auto;
            }

            .carousel__intro--has-cta + & {
                bottom: -17px;
            }

            .carousel--quotes & {
                left: 0;
                bottom: 30px;
                top: auto;
                justify-content: center;
                z-index: 1;
            }

            &:first-child:last-child {
                position: static;
                margin-right: -$site-gutter;
                margin-bottom: 18px;
            }
        }

            .carousel__left,
            .carousel__right {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                padding: 0;
                background: transparent;
                border: none;

                &[disabled] {
                    opacity: 0.3;
                    pointer-events: none;
                }

                .carousel--quotes & {
                    svg {
                        fill: $c-white;
                    }
                }

                svg {
                    transition: fill .3s;
                }

                &:hover,
                &:focus {
                    svg {
                        fill: $c-brand;
                    }
                }
            }

            .carousel__left {
                margin-right: 10px;

                .carousel--quotes & {
                    margin-right: 0;
                }

                svg {
                    transform: scaleX(-1);
                }
            }

    .carousel__container {
        @include hideScrollbars;
        @include margin(bottom, 20px);

        display: flex;
        overflow: auto;

        .carousel--active & {
            padding-left: $site-gutter;
            padding-bottom: 0;
            padding-top: 2px;

            @include breakpoint(tablet) {
                @include margin(bottom, 10px);
                padding-left: 0;
            }

            .carousel--quotes & {
                position: relative;
            }

            .carousel--reset & {

                @include breakpoint(tablet) {
                    padding-right: $site-gutter--mobile;
                }
            }
        }

        .carousel--quotes &,
        .carousel--site-links &,
        .carousel--callouts & {
            flex-wrap: wrap;
            margin-left: -25px;
            padding-bottom: 2px;

            @include breakpoint(tablet) {
                @include margin(bottom, 10px);

                flex-wrap: nowrap;
                overflow: auto;
                margin-right: 0;
                margin-left: -$site-gutter--mobile;
                padding-left: $site-gutter--mobile;
            }
        }

        .carousel--quotes &,
        .carousel--callouts & {
            margin-left: 0;
            flex-wrap: nowrap;
            justify-content: space-between;

            @include breakpoint(tablet) {
                margin-left: -$site-gutter--mobile;
            }
        }

        // Quote carousel has not margins or padding between items
        .carousel--quotes & {
            @include breakpoint(tablet) {
                padding-left: 0;
                margin-left: 0;
            }
        }

        @each $name, $breakpoint in $sizes {
            .carousel--active:not(.carousel--quotes)[data-carousel-activate="#{$name}"] & > * {
                @include breakpoint($breakpoint) {
                    width: calc(74vw - #{$site-gutter--mobile});
                    min-width: calc(74vw - #{$site-gutter--mobile});
                }
            }
        }

        .carousel--active:not(.carousel--quotes) & > * {
            *:focus {
                outline: none;
            }

            &:first-child {
                @include breakpoint(full) {
                    margin-left: calc((0.5 * (100vw - (#{$site-max-width} - (2 * #{$site-gutter})))) - 80px);
                }


                @include breakpoint(desktop) {
                    margin-left: 0;
                }

                @include breakpoint(tablet) {
                    margin-left: $site-gutter--mobile;
                }
            }

            &:last-child {
                @include breakpoint(full) {
                    margin-right: calc(0.5 * (100vw - (#{$site-max-width} - (2 * #{$site-gutter}))));
                }

                @include breakpoint(desktop) {
                    margin-right: $site-gutter;
                }

                @include breakpoint(tablet) {
                    margin-right: $site-gutter--mobile;
                }
            }
        }

        // remove the bottom padding if last block
        .block:last-child & {
            padding-bottom: 0;
        }
    }
