$browser-bar-dot: 6px;
$browser-bar-dot-gap: 3px;
$browser-bar-dot-left: 8px;

.browser {
    box-shadow: 0 0 8px 4px rgba($c-black, 0.05);
    border-radius: 5px 5px 0 0;
}

    .browser__bar {
        background-color: $c-browser;
        border-radius: 5px 5px 0 0;
        height: 16px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .browser__dot {
        background-color: $c-browser-highlight;
        border-radius: $browser-bar-dot;
        width: $browser-bar-dot;
        height: $browser-bar-dot;
        margin-top: 0 - ($browser-bar-dot / 2);
        position: absolute;
        top: 50%;
        left: $browser-bar-dot-left;
        transition: background-color .3s ease;

        .browser:hover & {
            background-color: #FF5B52;
        }

        & + & {
            left: $browser-bar-dot-left + ($browser-bar-dot) + $browser-bar-dot-gap;
            transition-delay: .05s;
            .browser:hover & {
                background-color: #E5C027;
            }
        }
        & + & + & {
            left: $browser-bar-dot-left + ($browser-bar-dot * 2) + ($browser-bar-dot-gap * 2);
            transition-delay: .1s;
            .browser:hover & {
                background-color: #53C22B;

            }
        }
    }

    .browser__address {
        background-color: $c-browser-highlight;
        @include rem(6px);
        line-height: 10px;
        max-width: 200px;
        width: 100%;
        height: 10px;
        text-align: center;
        border-radius: 1px;
        color: $c-browser-text;
        transition: color .3s ease;

        .browser:hover & {
            color: $c-browser-text-highlight;
        }
    }
