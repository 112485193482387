.service {
	width: calc(25% - #{25px * 2});
	margin: 27px 25px;
	padding-top: 10px;
	border-top: 1px solid $c-black;

	.block__services &:last-child {
		display: none;

		@include breakpoint(phablet) {
			display: block;
		}
	}

	@include breakpoint(tablet) {
		margin: 27px 10px;
		width: calc(50% - 20px);
	}

	@include breakpoint(phablet) {
		@include margin(bottom, 20px);

		width: 100%;
		margin-top: 0;
		margin-right: 0;
		margin-left: 0;
		padding-top: 0;
		padding-right: 20px;
		border-top: none;

		.carousel--active & {
			margin-bottom: 0;
		}
	}

	&--full {
		width: 100%;
        text-align: center;
        border-top: none;
        margin-top: 90px;
        margin-bottom: 130px;
        margin-left: 0;
        margin-right: 0;

        .service__title {
            display: block;
            margin-bottom: 10px;

            @include breakpoint(phablet) {
                margin-top: 0;
                display: flex;
            }
        }

        @include breakpoint(phablet) {
            margin-top: 20px;
            text-align: left;
        }
    }
}

	.service__link {
		display: block;
		color: inherit;
		text-decoration: none;
	}

		.service__title {
			@include font(18px, 30px);
			@include mediumText;
			@include margin(bottom, 3px);

			display: flex;
			align-items: center;

			svg {
				margin-left: 13px;
				transition: transform .2s ease;

				.service__link:hover & {
					transform: translateX(5px);
					fill: $c-brand;
					@include breakpoint(tablet) {
						fill: inherit
					}
				}
			}

			.service__link:hover & {
				color: $c-brand;

				@include breakpoint(tablet) {
					color: inherit;
				}
			}
		}

		.service__text {
			@include font(16px, 26px);
		}
