.block--intro {
    .page-intro__image {
        @include breakpoint(tablet) {
            margin-top: 30px;
        }
    }

    &.block--images-1 {
        margin-top: 70px
    }
}
