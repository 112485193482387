.block--benefits {
    .page-intro {
        @include breakpoint(tablet) {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    .page-intro__title {
        @include breakpoint(tablet) {
            text-align: left;
        }
    }

    .carousel__container {
        flex-wrap: wrap;
        @include breakpoint(tablet) {
            flex-wrap: nowrap;
            padding-left: 0;
            scroll-snap-type: x mandatory;
        }
    }

    .benefit {
        width: calc(100% /3);
        padding-bottom: 50px;

        &:nth-last-child(-n+3) {
            padding-bottom: 0;
        }

        @include breakpoint(tablet) {
            min-width: 300px;
            width: calc(66vw);
            text-align: left;
            padding-right: 15px;
            display: flex;
            flex-direction: column;
            padding-bottom: 0;
            scroll-snap-align: start;
        }
    }

    .benefit__icon {
        @include breakpoint(tablet) {
            margin-left: 0;
            margin-bottom: 15px;
            margin-top: 0;
        }
    }

    .carousel__container.carousel__container {
        @include breakpoint(tablet) {
            padding-bottom: 0;
        }
    }
}
