.block--services {
    .wrap {
        max-width: #{1050px + ($site-gutter * 2)};

        @include breakpoint(mobile) {
            max-width: #{533px + ($site-gutter--mobile * 2)};
        }
    }

		.block__title {
			@include font(25px, 33px);
			@include mediumText;
			@include margin(bottom, 53px);
			
			text-align: center;

			@include breakpoint(mobile) {
				@include font(21px, 30px);
				@include margin(bottom, 26px);
				text-align: left;
			}
		}

		.block__services {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			@include breakpoint(mobile) {
				display: block;
				margin: 0;
			}

			.carousel__container {
				@include breakpoint(tablet) {
					flex-wrap: wrap;
				}
				@include breakpoint(phablet) {
					flex-wrap: nowrap;
				}
			}
		}
}
