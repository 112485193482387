$quad-image-padding: 15px;

.block--inline-image {
    @include breakpoint(tablet) {
        margin-top: $block-vertical-padding-mobile--small;
    }

    .block__images {
        position: relative;
    }

    .blocks__text {
        hyphens: none;
    }

    .block__images--quad {
        @include aspect(608 / 567);

        @include breakpoint(tablet) {
            margin-bottom: 15px;
        }

        @include breakpoint(phablet) {
            @include aspect(335 / 375);
        }
    }

        .block__image {
            @include aspect(8 / 6);

            @include breakpoint(tablet) {
                @include aspect(342 / 206);
            }

            img {
                width: 100%;
                max-width: 100%;
                height: auto;
            }
        }

        .block__image--alt {

            @include breakpoint(tablet) {
                @include aspect(1);

                width: 78%;
            }
        }

        .block__image--offset {
            @include aspect(1);

            position: absolute;
            top: 50%;
            right: 0;
            width: 48%;
            transform: translate(25%, -50%);

            @include breakpoint(tablet) {
                transform: translate(0, -50%);
            }
        }

        &.block--image-layout-squares {
            .block__images {
                padding-bottom: 60px;

                @include breakpoint(tablet) {
                    transform: translateY(11%);
                }
            }

            .block__image--alt {
                @include aspect(1);
                width: 65%;
                margin-left: auto;
            }

            .block__image--offset {
                @include aspect(1);
                top: auto;
                bottom: 0;
                right: auto;
                left: 0;
                width: 48%;
                transform: none;

                @include breakpoint(tablet) {
                    transform: translate(0, -50%);
                }
            }

            @include breakpoint(tablet) {
                .wrap .grid {
                    display: flex;
                    flex-direction: column-reverse;
                }

                .block__images {
                    padding-bottom: 0;
                }

                .block__content {
                    padding-top: 0;
                    padding-bottom: 30px;
                }
            }
        }

        .block__image--quad {
            @include aspect(1);

            position: absolute;

            @include breakpoint(phablet) {
                padding: 0;
            }
        }

        .block__image--quad-1 { 
            top: 10%;
            left: 0;
            width: 50%;
            padding: 0 $quad-image-padding $quad-image-padding 0;

            @include breakpoint(phablet) {
                padding: 0 ($quad-image-padding/2) ($quad-image-padding/2) 0;
            }
        }

        .block__image--quad-2 { 
            top: 0;
            right: calc(((1 / 3) * (1 / 2)) * 100%);
            width: calc((1 / 3) * 100%);
            padding: 0 0 $quad-image-padding $quad-image-padding;

            @include breakpoint(phablet) {
                padding: 0 0 ($quad-image-padding/2) ($quad-image-padding/2);
                width: 50%;
                right: 0;
            }
        }

        .block__image--quad-3 { 
            left: calc(((1 / 3) * (1 / 2)) * 100%);
            bottom: 0;
            width: calc((1 / 3) * 100%);
            padding: $quad-image-padding $quad-image-padding 0 0;

            @include breakpoint(phablet) {
                padding: ($quad-image-padding/2) ($quad-image-padding/2) 0 0;
                width: 50%;
                left: 0;
            }
        }

        .block__image--quad-4 { 
            right: 0;
            width: 50%;
            bottom: 10%;
            padding: $quad-image-padding 0 0 $quad-image-padding;

            @include breakpoint(phablet) {
                padding: ($quad-image-padding/2) 0 0 ($quad-image-padding/2);
            }
        }

    .block__content {
        padding: 0 105px;

        .cta {
            @include breakpoint(tablet) {
                display: table;
            }
        }

        .cta + .cta {
            @include breakpoint(tablet) {
                margin-left: 0;
                margin-top: 15px;
            }
        }

        @include breakpoint(laptop) {
            padding: 0 50px;
        }

        @include breakpoint(tablet) {
            padding: 0;
        }
    }

    .block__content--twin {
        @include breakpoint(tablet) {
            padding-top: 10px;
            text-align: left;
        }

        .block__text {
            @include font(16px, 26px);

            @include breakpoint(tablet) {
                @include font(15px, 26px);

                letter-spacing: 0.47px;
            }
        }
    }

    .block__content__quad {
        //@adam todo
    }

        .block__title {
            @include block-title;

            @include breakpoint(tablet) {
                @include font(21px, 28px);
                @include margin(bottom, 16px);
            }
        }

        .block__text {
            @include font(18px, 25px);
            @include margin(bottom, 10px);

            @include breakpoint(tablet) {
                @include font(17px, 25px);
                @include margin(bottom, 16px);
            }
        }

    .block__text + .block__body {
        margin-top: 40px;
    }

    .cta + .cta {
        margin-left: 35px;
    }
}
