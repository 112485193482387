.block--features {

	.block__title {
		@include font(25px, 33px);
		@include mediumText;
		@include margin(bottom, 70px);

		text-align: center;

		@include breakpoint(tablet) {
			@include font(17px, 28px);
			@include margin(bottom, 30px);

			letter-spacing: 0.3px;
		}
	}

	.block__title--small-margin {
		@include margin(bottom, 25px);
	}

	.block__content {
		max-width: 820px;
		margin: 0 auto;
	}

	.block__content--wide {
		max-width: 1024px;
	}

	.grid {
		margin-bottom: -70px;

		@include breakpoint(tablet) {
			margin-bottom: -40px;
		}

		> * {
			margin-bottom: 70px;

			@include breakpoint(tablet) {
				margin-bottom: 40px;
			}
		}
	}
}
