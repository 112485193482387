.block--team-quotes {
    .carousel__header {
        padding-right: 0;
        padding-left: 0;
    }

    .carousel--active .carousel__container {
        padding-left: 0;
        padding-right: 0;
    }

    .carousel--active .carousel__controls {
        right: 0
    }

    .carousel__title {
        @include margin(bottom, 40px);
        text-align: center;

        @include breakpoint(tablet) {
            @include margin(bottom, 20px);
        }
    }
}
