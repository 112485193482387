.carousel-item {
    min-width: 40%;
    padding-left: 20px;

    @include breakpoint(tablet) {
        min-width: 80%;
    }

    &__image {
        margin-bottom: 35px
    }

    &__text {
        @include font(16px, 26px);
    }
}
