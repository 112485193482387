.block--principles {

    .block__title {
        @include font(25px, 30px);
        @include mediumText;
        @include margin(bottom, 38px);

        text-align: center;

        @include breakpoint(tablet) {
            @include font(21px, 33px);
            @include margin(bottom, 25px);
        }
    }

    .block__principles {
        @include grid;

        max-width: 840px;
        margin-right: auto;
        margin-bottom: -46px;
        margin-left: auto;

        @include breakpoint(tablet) {
            display: block;
            margin-bottom: -22px;
        }

        @for $principles from 1 to $principles-limit {
            $columnLimit: ceil($principles / 2);

            &[data-principles="#{$principles}"] {
                @include gridRows($columnLimit, 1fr);
                @include gridColumns(1fr 1fr);

                > :nth-child(-n+#{$columnLimit}) {
                    @include gridColumn(1);
                }

                > :nth-child(n+#{$columnLimit + 1}) {
                    @include gridColumn(2);
                }

                @for $i from 1 to $columnLimit + 1 {
                    > :nth-child(#{$columnLimit}n + #{$i}) {
                        @include gridRow($i);
                    }
                }
            }
        }

        > * {
            padding-right: 40px;
            padding-bottom: 46px;

            @include breakpoint(tablet) {
                padding-right: 0;
                padding-bottom: 22px;
            }
        }
    }
}
