.approach {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

    @include breakpoint(phablet) {
        text-align: left;
        padding-right: 20px;
        padding-left: 0;
    }
}

.approach--grid {
    display: flex;
    text-align: left;

    @include breakpoint(phablet) {
        flex-direction: column;
        align-items: flex-start;
    }
}

    .approach__image {
        @include margin(bottom, 20px);

        display: flex;
        width: 56px;
        max-height: 62px;
        margin-right: auto;
        margin-left: auto;
        
        svg {
            width: 100%;
            height: auto;
        }

        @include breakpoint(phablet) {
            margin-left: 0;
        }

        .approach--grid & {
            margin-left: 0;
            margin-right: 40px;
            margin-top: 6px;
            flex-shrink: 0;

            @include breakpoint(phablet) {
                margin: 0 0 20px;
            }
        }
    }

        .approach__content {}

            .approach__title {
                @include font(18px, 30px);
                @include mediumText;
                @include margin(bottom, 3px);

                @include breakpoint(tablet) {
                    @include font(17px, 30px);
                }
            }

            .approach__text {
                @include font(16px, 26px);

                @include breakpoint(tablet) {
                    @include font(15px, 26px);
                }
            }
