.gallery {
    display: flex;
    flex-direction: column;
}

    .gallery--mobile {
        padding-top: 0;
        color: $c-white;
        background: $c-black;
    }

    .gallery--carousel {
        background: transparent;
        color: $c-black;
    }

    .gallery__header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 0;
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        position: relative;

        @include breakpoint(tablet) {
            padding-right: $site-gutter--mobile;
            padding-left: $site-gutter--mobile;
        }

        .gallery--black & {
            background: $c-black;
            color: $c-white;
        }
    }

    .gallery__close {
        svg {
            .gallery.gallery--black &,
            .gallery.gallery--mobile & {
                stroke: $c-white;
            }

            .gallery & {
                @include breakpoint(tablet) {
                    stroke: $c-white;
                }
            }
        }
    }

        .gallery__alt {
            position: absolute;
            left: 20px;
            cursor: pointer;

            @include breakpoint(tablet) {
                .gallery & {
                    display: none;
                }
            }
        }

        .gallery__nav {
            padding: 10px;
            background: transparent;
            border: none;

            &[data-action="previous"] {
                transform: scaleX(-1);
            }

            svg {
                display: block;
                fill: currentColor;
            }

            .gallery--carousel & {
                &[data-action="next"] {
                    margin-left: 20px;
                    @include breakpoint(mobile) {
                        margin-left: 10px;
                    }
                }
                &[data-action="previous"] {
                    margin-right: 20px;
                    @include breakpoint(mobile) {
                        margin-right: 10px;
                    }
                }
            }
        }

        .gallery__nav--disabled {
            opacity: .5;
        }

        .gallery__title {
            @include font(14px, 30px);
            @include mediumText;

            margin: 0 25px;
            letter-spacing: 0.4px;
            text-align: center;
            text-transform: uppercase;
        }

    .gallery__image {
        height: calc(100vh - 61px);
        overflow: auto;
        background: $c-white;

        img {
            display: block;
            width: 100%;
            max-width: 1400px;
            margin: 0 auto;
        }
    }

    .gallery__content {
        flex: 1 1 auto;
        display: block;
        position: relative;
        background-color: $c-white;

        .gallery--mobile & {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px 0;

            @include breakpoint(mobile) {
                background-color: $c-black;
                padding: 0;
            }
        }

        .gallery--carousel & {
            @include breakpoint(mobile) {
                background-color: transparent;
            }
        }

        .gallery--black & {
            background-color: $c-black;
        }
    }

    .gallery__swiper {
        position: relative;
        width: 100%;
    }

    .gallery__style {
        display: flex;
        height: 100%;
        .gallery--mobile & {
            border-radius: 30px;
            background-color: $c-black;
            max-width: 320px;
            width: 100%;
            position: relative;
            box-shadow: 0 0 5px 1px rgba($c-black, .3);

            padding: 40px 10px 60px;
            max-height: none;
            @include breakpoint(phablet) {
                padding: 40px 10px 60px;
            }

            @include breakpoint(mobile) {
                border-radius: 20px;
                padding: 30px 8px 40px;
            }

            .overlay--gallery & {
                max-height: 700px;

                @include breakpoint(phablet) {
                    max-height: 100%;
                    max-width: none;
                    border-radius: 0;
                    padding: 0;
                }
            }
        }

        .gallery--carousel & {
            width: calc(100% - 110px);
            @include breakpoint(mobile) {
                width: calc(100% - 90px);
            }
        }
    }
