.block--timeline-event {
    @extend .block--inline-image;
    margin: 100px 0;
    position: relative;

    &.block--timeline-event-last:before,
    &.block--timeline-event-0:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 12px;
        height: 12px;
        background: $c-brand;
        top: -50px;
        border-radius: 12px;
        transform: translateX(calc(-50% + 1px));
        z-index: -1;
        border-right: 1px solid $c-brand;
    }

    &.block--timeline-event-last:before {
        top: auto;
        bottom: -50px;
    }

    &.block--timeline-event-last:after {
        bottom: -50px;
    }

    &.block--timeline-event-0 {
        .wrap {
            &:before {
                content: '';
                position: absolute;
                top: -50px;
                left: 50%;
                width: 1px;
                height: 50px;
                background: $c-brand;
                z-index: -1;
            }
        }
    }

    &:after {
        content: '';
        position: absolute;
        left: 50%;
        width: 1px;
        background: $c-brand;
        top: 0;
        bottom: -200px;
        z-index: -1;
    }

    &.block--images-1 {
        .block__image {
            @include aspect(5/3);
        }

        .grid {
            @include grid;
            @include gridColumns(repeat(12, [col-start] 1fr));
            grid-column-gap: 25px;
            align-items: center;

            @include breakpoint(tablet) {
                @include gridRows([col-start] minmax(min-content,100px), 2);
            }
        }

        .grid > div:nth-child(2) {
            @include gridColumn(col-start 8/ span 5);
            width: auto;
            padding: 0;
            margin-bottom: 0;
        }

        &.block--align-right .grid > div:nth-child(2) {
            .block__content {
                padding-left: 0;
            }

            @include breakpoint(tablet) {
                @include gridColumn(col-start 1 / 13);
                @include gridRow(2, 1);
                margin-top: -80px;
                background-color: $c-white;
                margin-left: 0px;
                margin-right: 15px;

                .block__content {
                    padding: 20px;
                }
            }
        }

        .grid > div:nth-child(1) {
            @include gridColumn(col-start / span 7);
            width: auto;
            padding: 0;
            margin-bottom: 0;

            @include breakpoint(tablet) {
                @include gridColumn(col-start 1 / 13);
                @include gridRow(1, 1);
                margin-left: 30px;
                margin-right: 0;
            }
        }

        &.block--align-left .grid > div:nth-child(2) {
            @include gridColumn(col-start 7/ span 5);
            width: auto;
            padding: 0;
            margin-bottom: 0;
            @include breakpoint(tablet) {
                @include gridColumn(col-start 1 / 13);
                @include gridRow(2, 1);
                margin-top: -80px;
                background-color: $c-white;
                margin-left: 15px;

                .block__content {
                    padding: 20px;
                }
            }
        }

        &.block--align-left .grid > div:nth-child(1) {
            @include gridColumn(col-start 2/ span 5);
            width: auto;
            padding: 0;
            margin-bottom: 0;

            @include breakpoint(tablet) {
                @include gridColumn(col-start 1 / 13);
                @include gridRow(1, 1);
                margin-left: 0;
                margin-right: 30px;
            }
        }
    }

    &.block--images-2 {
        .block__image--alt {
            @include aspect(1);
            max-width: 500px;

            @include breakpoint(tablet) {
                max-width: none;
                width: auto;
            }
        }

        .block__image--offset {
            @include aspect(3/4);
            max-width: 80px * 3 + 2 * 25px;
            transform: translate(90%, -50%);
            width: 60%;

            @include breakpoint(tablet) {
                display: none;
            }
        }

        .grid {
            @include grid;
            @include gridColumns(repeat(12, [col-start] 1fr));
            grid-column-gap: 20px;
            align-items: center;

            @include breakpoint(tablet) {
                grid-column-gap: 25px;
                @include gridRows([col-start] minmax(min-content,100px), 2);
            }
        }

        .grid > div:nth-child(2) {
            @include gridColumn(col-start 8/ span 5);
            width: auto;
            padding: 0;
            margin-bottom: 0;

            @include breakpoint(tablet) {
                @include gridColumn(col-start 1 / 13);
                @include gridRow(2, 1);
                z-index: 1;
                margin-top: -80px;
                background-color: $c-white;
                margin-left: 0px;
                margin-right: 15px;

                .block__content {
                    padding: 20px;
                    text-align: right;
                }
            }
        }

        .grid > div:nth-child(1) {
            @include gridColumn(col-start / span 7);
            width: auto;
            padding: 0;
            margin-bottom: 0;

            @include breakpoint(tablet) {
                @include gridColumn(col-start 1 / 13);
                @include gridRow(1, 1);
                margin-left: 30px;
                margin-right: 0;
            }
        }

        &.block--align-right  {
            .block__image--alt {
                margin-left: auto;
            }
            .block__image--offset {
                transform: translate(-50px, -50%);
                right: auto;
                left: 0;
            }
        }

        &.block--align-left .grid > div:nth-child(2) {
            @include gridColumn(col-start 8/ span 5);
            width: auto;
            padding: 0;
            margin-bottom: 0;

            .block__content {
                padding-right: 0;
            }

            @include breakpoint(tablet) {
                @include gridColumn(col-start 1 / 13);
                @include gridRow(2, 1);
                margin-top: -80px;
                background-color: $c-white;
                margin-left: 15px;
                margin-right: 0;

                .block__content {
                    padding: 20px;
                    text-align: left;
                }
            }
        }

        &.block--align-left .grid > div:nth-child(1) {
            @include gridColumn(col-start 1/ span 5);
            width: auto;
            padding: 0;
            margin-bottom: 0;

            @include breakpoint(tablet) {
                @include gridColumn(col-start 1 / 13);
                @include gridRow(1, 1);
                margin-left: 0;
            }
        }
    }

    .block__title {
        @include font(90px, 100px);
        @include semiBoldText;

        display: inline-block;
        border-bottom: 5px solid $c-brand;
        margin-bottom: 20px;

        @include breakpoint(tablet) {
            @include font(60px, 70px);
        }
    }

    .block__text {
        @include font(18px, 33px);
        @include lightText;
    }

    .block__content--twin .block__text {
        @include font(18px, 33px);
        @include lightText;
    }

    .block__image--client {
        opacity: 0.4;
        margin-top: 30px;

        img {
            max-height: 150px;
            width: auto;
        }

        &:before,
        &:after {
            display: none;
        }
    }

    .grid--reverse {
        .block__content {
            text-align: right;
        }

        .block__title {
            text-align: right;
        }
    }
}
