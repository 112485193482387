@use 'sass:math';

/// Set a relative font sizse and a unitless line height, based on the required line height and a font size
///
/// @param {Number} $fontSize Font size
/// @param {Number} $lineHeight Line height
/// @param {Bool} $em Use re
/// @author Joe Adcock <joe@d3r.com>

@mixin font($fontSize, $lineHeight, $em: false) {
    @if $em {
        font-size: math.div($fontSize, $base-px) * 1em;
    } @else {
        @include rem($fontSize);
    }

    line-height: math.div($lineHeight, $fontSize);
}
