.gallery-card {

    .carousel & {
        margin-right: 22px;

        @include breakpoint(phablet) {
            flex: 0 0 auto;
            width: calc(70vw - #{$site-gutter--mobile});
            margin-right: $site-gutter--mobile;
        }

        &:last-child {
            margin-right: $site-gutter;

            @include breakpoint(phablet) {
                margin-right: $site-gutter--mobile;
            }
        }
    }
}

    .gallery-card__link {
        display: block;
        text-decoration: none;
        color: inherit;
        overflow: hidden;

        .carousel & {
            width: 712px;
            min-height: 712px;

            @include breakpoint(phablet) {
                width: auto;
            }
        }
    }

        .gallery-card__background {
            padding: 65px 65px 0;
        }

        .gallery-card__header {
        }

        .gallery-card__image {
            @include aspect(9 / 10);
            @include margin(bottom, 24px);

            @include breakpoint(tablet) {
                @include margin(bottom, 10px);
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .gallery-card__title {
            @include font(18px, 20px);
            @include mediumText;
            @include margin(bottom, 10px);

            letter-spacing: 0.3px;
        }

        .gallery-card__text {
            @include font(16px, 20px);
        }
