.block--logos {
    @include breakpoint(tablet) {
        overflow: hidden;
        margin-top: $block-vertical-padding-mobile--small;
    }

    .block__logos {
        display: grid;
        grid-template-columns: repeat(4, minmax(200px, 1fr));
        max-width: 800px;
        margin: 0 auto;

        @include breakpoint(tablet) {
            grid-template-columns: 2fr 1fr 1fr;
        }
    }

        .block__image {
            display: grid;
            place-items: center;

            svg {
                display: block;
                max-width: 100%;
                object-fit: contain;
            }

            @for $i from 1 through 8 {

                &:nth-child(#{$i}) {
                    order: $i;
                }
            }

            @include breakpoint(tablet) {

                // This is very fragile

                &:nth-child(2) {
                    margin: 0 -20px;
                }

                &:nth-child(5),
                &:nth-child(8) {
                    margin-top: -30px;
                }

                &:nth-child(7) {
                    order: 1;
                    margin: 0 -20px;
                }

                &:nth-child(8) {
                    grid-column: 2/4;
                }

                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(6) {
                    display: none;
                }
            }
        }
}
