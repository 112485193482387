.block--definitions {
	margin: 140px 0 120px;

	@include breakpoint(tablet) {
		margin: 60px 0;
	}

	.wrap {
        max-width: #{700px + ($site-gutter * 2)};

        @include breakpoint(tablet) {
            max-width: none;
        }
    }

		.block__list {
			@include margin(bottom, 30px);

			display: flex;

			@include breakpoint(tablet) {
				@include margin(bottom, 50px);
				display: block;
			}

			dt {
				@include font(25px, 30px);
				@include mediumText;

				flex: 0 0 220px;
				margin-right: 30px;
				text-align: right;

				@include breakpoint(tablet) {
					@include margin(bottom, 7px);
					text-align: left;
				}
			}

			dd {
				@include font(16px, 26px);

				@include breakpoint(tablet) {
					@include font(15px, 26px);
				}
			}
		}
}
