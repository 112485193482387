.block--site-links,
.block--other-services {

    .block__image {
        @include aspect(605 / 460);

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }

    .block__content {
        text-align: left;
    }

        .block__title {
            @include font(25px, 30px);
            @include mediumText;
            @include margin(bottom, 38px);

            @include breakpoint(tablet) {
                @include font(21px, 28px);
                @include margin(bottom, 16px);
            }
        }

        .block__links {
            display: flex;
            flex-wrap: wrap;
            margin-left: -25px;

            @include breakpoint(tablet) {
                flex-wrap: nowrap;
                width: calc(100% + #{$site-gutter--mobile * 2});
                margin-right: -$site-gutter--mobile;
                margin-left: -$site-gutter--mobile;
                padding-right: $site-gutter--mobile;
                padding-left: $site-gutter--mobile;
                padding-bottom: 20px;
                overflow: auto;
            }
        }

            .block__link-container {
                width: calc(50% - 50px);
                margin: 0 25px 44px;

                @include breakpoint(tablet) {
                    flex-grow: 0;
                    flex-shrink: 0;
                    width: calc(250px - 40px);
                    margin: 0 40px 0 0;
                }
            }

                .block__link {
                    display: block;
                    color: inherit;
                    text-decoration: none;

                    &:hover {
                        svg {
                            fill: $c-brand;
                            transform: translateX(5px);

                            @include breakpoint(tablet) {
                                fill: inherit;
                            }
                        }

                        .block__subtitle {
                            color: $c-brand;
                            @include breakpoint(tablet) {
                                color: inherit;
                            }
                        }
                    }
                }

                    .block__subtitle {
                        @include font(18px, 30px);
                        @include mediumText;
                        @include margin(bottom, 3px);

                        @include breakpoint(tablet) {
                            @include font(17px, 30px);
                        }

                        transition: .2s color ease;

                        svg {
                            margin-left: 10px;
                            transition: transform .2s ease;
                        }
                    }

                    .block__text {
                        @include font(16px, 26px);

                        @include breakpoint(tablet) {
                            @include font(15px, 26px);
                        }
                    }
    }
