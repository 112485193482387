.block--table {
    margin: 90px 0;

    @include breakpoint(tablet) {
        margin: 60px 0;
    }

	table {
		@include font(14px, 25px);

		width: 100%;
		letter-spacing: 0.4px;
	}

		th,
		td {
			padding: 9px 15px 6px;
			border: 1px solid $c-table-border;
		}

		th {
			background: $c-table-background;
		}

		a {
			color: inherit;
			text-decoration: underline;
		}
}
