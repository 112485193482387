.block--projects {
    margin: 90px 0;

    @include breakpoint(tablet) {
        margin: 60px 0;
    }

	.carousel {
		@include margin(bottom, 30px);
	}

	.block__footer {
		text-align: center;
	}
}
