.block--fit {
    @include lightondark;

    padding: 130px 0;
    color: $c-white;
    background: $c-black;
    text-align: center;

    @include breakpoint(tablet) {
        padding: 70px 0;
    }

    .wrap {
        max-width: 1100px;

        @include breakpoint(tablet) {
            max-width: #{533px + ($site-gutter--mobile * 2)};
        }
    }

    .block__title {
        @include font(25px, 33px);
        @include mediumText;
        @include margin(bottom, 50px);

        @include breakpoint(tablet) {
            @include font(21px, 33px);
            @include margin(bottom, 30px);

            letter-spacing: 0.4px;
        }
    }

    .block__text {
        @include font(25px, 30px);
        @include mediumText;
        @include margin(bottom, 40px);

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        @include breakpoint(tablet) {
            @include font(21px, 30px);
            @include margin(bottom, 40px);

            letter-spacing: 0.4px;
        }

        @include breakpoint(phablet) {
            display: block;
        }
    }

    .field {
        margin-bottom: 20px;

        label {
            white-space: nowrap;
        }

        @include breakpoint(tablet) {
            @include margin(bottom, 25px);
        }

        + .field {
            margin-left: 5px;

            @include breakpoint(tablet) {
                margin-left: 0;
            }
        }
    }

    .field {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &:last-child {
            flex-basis: 100%;
        }
    }

    .select-replace {
        @include breakpoint(phablet) {
            flex-wrap: wrap;
            justify-content: center;
            padding-left: 0.65ch;
        }

        &:before {
            margin-left: 10px;
            color: $c-brand;

            @include breakpoint(phablet) {
                margin-left: 0;
                padding-right: 10px;
                white-space: nowrap;
            }
        }

        &:after {

        }

        svg {
            order: 2;
            fill: $c-brand;
            width: 15px;
            height: 10px;
            vertical-align: middle;
        }

        label {
            @include breakpoint(phablet) {
                white-space: nowrap;
                padding-right: .65ch;
            }
        }

        select {
            color: initial;
            background: initial;
        }
    }
}
