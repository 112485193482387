/// Hide the scrollbars for an element
///
/// @author Joe Adcock <joe@d3r.com>

@mixin hideScrollbars {
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
}
