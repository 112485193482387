.block--map {
    .block__title {
        @include block-title;
    }

    .block__map {
        @include aspect(4 / 3);
    }

    address {
        margin-bottom: 10px;
    }
}
