.block--screenshot-gallery,
.block--gallery {
	margin-top: 65px;

	@include breakpoint(tablet) {
		margin-top: $block-vertical-padding-mobile--small;
	}

	.carousel {
		@include margin(bottom, 60px);
	}

	.block__footer {
		text-align: center;

		@include breakpoint(tablet) {
			margin-top: 30px;
		}
	}
}
