.callout {

    .carousel & {
        margin-right: 26px;
        flex-basis: calc(33.33% - 18px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include breakpoint(tablet) {
            flex: 0 0 auto;
            width: calc(72vw - #{$site-gutter--mobile});
            margin-right: $site-gutter--mobile;
        }

        &:last-child {
            margin-right: 0;

            @include breakpoint(tablet) {
                margin-right: $site-gutter--mobile;
            }
        }

        a {
            margin-top: auto
        }
    }
}

    .callout__image {
        @include aspect(396 / 290);
        @include margin(bottom, 24px);

        @include breakpoint(tablet) {
            @include aspect(342 / 206);
            @include margin(bottom, 20px);
        }

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
            transition: transform .5s ease;
        }

        &:hover img {
            transform: scale(1.04);
        }
    }

    .callout__title {
        @include font(25px, 33px);
        @include mediumText;
        @include margin(bottom, 10px);

        letter-spacing: 0.8px;

        @include breakpoint(tablet) {
            @include font(21px, 28px);

            letter-spacing: 0.6px;
        }
    }

    .callout__text {
        @include font(18px, 26px);
        @include margin(bottom, 10px);

        @include breakpoint(tablet) {
            @include font(17px, 25px);

            @include margin(bottom, 16px);
        }
    }
