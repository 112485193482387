@use 'sass:math';

.project-card {

    .carousel & {
        margin-right: 22px;

        @include breakpoint(phablet) {
            flex: 0 0 auto;
            width: calc(70vw - #{$site-gutter--mobile});
            margin-right: $site-gutter--mobile;
        }

        &:last-child {
            margin-right: $site-gutter;

            @include breakpoint(phablet) {
                margin-right: $site-gutter--mobile;
            }
        }
    }
}

    .project-card__link {
        display: block;
        text-decoration: none;
        color: inherit;

        .carousel & {
            width: 396px;

            @include breakpoint(phablet) {
                width: auto;
            }
        }
    }

        .project-card__image {
            @include aspect(math.div(396, 238));
            @include margin(bottom, 24px);

            @include breakpoint(tablet) {
                @include margin(bottom, 10px);
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .project-card__title {
            @include font(18px, 20px);
            @include mediumText;
            @include margin(bottom, 10px);

            letter-spacing: 0.3px;

            .project-card__link:hover & {
                text-decoration: underline;
            }
        }

        .project-card__text {
            @include font(16px, 20px);
        }
