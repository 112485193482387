/// Apply margin (in a given direction) to an element, unless it is the last child in its container
///
/// @param {String} $margin Margin bottom value
/// @author Joe Adcock <joe@d3r.com>

@mixin margin($direction, $margin) {
    margin-#{$direction}: $margin;

    &:last-child {
        margin-#{$direction}: 0;
    }
}
