.gallery-device {
    display: flex;

    .carousel & {
        margin-right: 22px;

        @include breakpoint(phablet) {
            flex: 0 0 auto;
            width: calc(70vw - #{$site-gutter--mobile});
            margin-right: $site-gutter--mobile;
        }

        &:last-child {
            margin-right: $site-gutter;

            @include breakpoint(phablet) {
                margin-right: $site-gutter--mobile;
            }
        }
    }
}

    .gallery-device__link {
        display: block;
        text-decoration: none;
        color: inherit;
        overflow: hidden;
        display: flex;
        position: relative;

        .carousel & {
            width: 712px;

            @include breakpoint(phablet) {
                width: auto;
            }
        }
    }

    .gallery-device__background {
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .gallery-device__mag {
        position: absolute;
        top: 14px;
        left: 14px;

        svg {
            fill: $c-white;
            width: 25px;
            height: 25px;
        }
    }

    .gallery-device__image {
        width: 458px;
        outline: 2px solid black;

        .gallery-device__device--tablet + & {
            width: 300px;
            margin-top: 29px;
        }

        .gallery-device__device--desktop + & {
            width: 494px;
            margin-top: -69px;
        }
    }

    .gallery-device__device {
        position: absolute;
    }
