.block--jobs {
    @extend .block--banner;

    .block__image {
        @include aspect(18 / 5);

        @include breakpoint(phablet) {
            @include aspect(6 / 5);
        }
    }

    .block__content {
        max-width: 700px;
    }

    .content-list__item {
        margin-bottom: 10px;
    }
}
