.principle {
    position: relative;
    padding-left: 40px + 56px;

    @include breakpoint(tablet) {
        padding-left: 0;
        text-align: center;
    }
}

    .principle__image {
        @include aspect(1);

        position: absolute;
        top: 9px;
        left: 0;
        width: 56px;

        @include breakpoint(tablet) {
            @include margin(bottom, 3px);

            position: static;
            margin-right: auto;
            margin-left: auto;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    .principle__title {
        @include font(18px, 30px);
        @include mediumText;
        @include margin(bottom, 3px);

        @include breakpoint(tablet) {
            @include font(17px, 30px);
        }
    }

    .principle__text {
        @include font(16px, 26px);

        @include breakpoint(tablet) {
            @include font(15px, 26px);
        }
    }
