.block--approaches {
    .block__title {
        @include font(25px, 30px);
        @include mediumText;
        @include margin(bottom, 45px);

        text-align: center;

        @include breakpoint(tablet) {
            @include font(21px, 33px);
            @include margin(bottom, 25px);
        }
    }

    .block__approaches {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include breakpoint(mobile) {
            display: block;
            margin: 0;
        }

        &--even .carousel__container {
            grid-template-columns: repeat(2, 1fr);
        }

        &--odd .carousel__container {
            grid-template-columns: repeat(3, 1fr);
        }

        .carousel__container {
            display: grid;
            flex-wrap: wrap;
            gap: 40px 10px;

            @include breakpoint(tablet) {
                grid-template-columns: repeat(2,  minmax(auto, 460px));
                > * {
                    //Centre the last item if it is an orphan
                    &:last-child:nth-child(2n - 1) {
                        grid-column-start: 1;
                        grid-column-end: 4;
                        max-width: 460px;
                        margin: auto;
                    }
                }
            }

            @include breakpoint(phablet) {
                display: flex;
                flex-wrap: nowrap;
            }

        }
    }

    .block__approaches--grid {
        > * {
            margin: 0 30px;

            @include breakpoint(tablet) {
                @include margin(bottom, 25px);

                margin-right: 0;
                margin-left: 0;
            }

            &:not(:nth-last-child(2)):not(:last-child) {
                margin-bottom: 45px;

                @include breakpoint(tablet) {
                    @include margin(bottom, 25px);

                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }
    }
}
