// Settings
@import 'settings/variables';
@import 'settings/breakpoints';

// Tools
@import 'tools/mixins';
@import 'tools/mixins/cover-image';
@import 'tools/mixins/font';
@import 'tools/mixins/grid';
@import 'tools/mixins/grid-column';
@import 'tools/mixins/grid-columns';
@import 'tools/mixins/grid-row';
@import 'tools/mixins/grid-rows';
@import 'tools/mixins/hide-scrollbars';
@import 'tools/mixins/margin';
@import 'tools/mixins/light-text';
@import 'tools/mixins/medium-text';
@import 'tools/mixins/semi-bold-text';

$block-vertical-padding-mobile--medium: $s-vertical--medium;
$block-vertical-padding-mobile--small: $s-vertical--small;
$block-vertical-padding-desktop--medium: $s-vertical--large;
$block-vertical-padding-desktop--large: $s-vertical--xlarge;

@mixin block-title {
    @include font(25px, 33px);
    @include mediumText;
    @include margin(bottom, 10px);

    letter-spacing: 0.8px;

    @include breakpoint(tablet) {
        @include font(21px, 28px);
    }
}

.blocks {
    margin-bottom: $block-vertical-padding-desktop--large;

    @include breakpoint(tablet) {
        margin-bottom: $block-vertical-padding-mobile--medium;
    }

    .header--static ~ #main &:first-child .block:first-child {
        margin-top: 0;
    }
}

.block {
    margin-top: $block-vertical-padding-desktop--medium;

    &:first-child {
        @include breakpoint(tablet) {
            margin-top: $block-vertical-padding-mobile--medium;
        }
    }

    @include breakpoint(tablet) {
        margin-top: $block-vertical-padding-mobile--medium;
    }

    &:last-child {
        margin-bottom: $block-vertical-padding-desktop--large;

        @include breakpoint(tablet) {
            margin-bottom: $block-vertical-padding-mobile--medium;
        }
    }

    .wrap & .wrap {
        max-width: none;
        padding: 0;
    }
}

.blocks--tight-bottom {
    margin-bottom: 90px;

    .block:last-child {
        margin-bottom: 90px;
    }
}

// Components
@import 'components/blocks/banner';
@import 'components/blocks/inline-image';
@import 'components/blocks/intro';
@import 'components/blocks/logos';
@import 'components/blocks/quote';
@import 'components/blocks/principles';
@import 'components/blocks/stats';
@import 'components/blocks/site-links';
@import 'components/blocks/projects';
@import 'components/blocks/projects-list';
@import 'components/blocks/gallery';
@import 'components/blocks/features';
@import 'components/blocks/our-work';
@import 'components/blocks/richtext';
@import 'components/blocks/table';
@import 'components/blocks/services';
@import 'components/blocks/approach';
@import 'components/blocks/fit';
@import 'components/blocks/definitions';
@import 'components/blocks/clients';
@import 'components/blocks/map';
@import 'components/blocks/cta';
@import 'components/blocks/jobs';
@import 'components/blocks/team-quotes';
@import 'components/blocks/benefits';
@import 'components/blocks/timeline-event';
@import 'components/blocks/form';
@import 'components/blocks/callouts';

@import 'components/carousel';
@import 'components/carousel-item';
@import 'components/team-card';
@import 'components/quote-card';
@import 'components/principle';
@import 'components/callout';
@import 'components/project-card';
@import 'components/gallery-card';
@import 'components/gallery-device';
@import 'components/feature';
@import 'components/gallery';
@import 'components/browser';
@import 'components/swiper';
@import 'components/service';
@import 'components/approach';
@import 'components/select-replace';

// Trumps
@import 'trumps/overlay';
