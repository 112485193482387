.select-replace {
    position: relative;
    display: flex;
    flex-direction: column;

    &:before {
        content: attr(data-value);
        height: $select-replace-height;
        padding: 4px 0;
        border-bottom: 1px solid $c-border;
        pointer-events: none;
        order: 1;
    }

    &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: $select-replace-height / 2;
        width: 7px;
        height: 7px;
        border-right: 1px solid $c-black;
        border-bottom: 1px solid $c-black;
        transform: translateX(-12px) rotate(45deg) skewX(10deg) skewY(10deg);
    }

    &.field-error:before {
        border-color: $c-error;
    }

    &.field-error:after {
        bottom: ($select-replace-height / 2) + 20px;
    }

    label {
        order: 0;
    }

    select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
    }

    option {
        @include font(18px, 24px);
    }

    p.error {
        order: 4;
    }
}

.select-replace--minimal {
    flex-direction: row;
    align-items: center;
    margin-right: 10px;

    &:after {
        .block--fit & {
            display: none;
            margin-right: 10px;
            @include breakpoint(tablet) {
                margin-right: 0;
            }
        }
    }

    &:before {
        height: auto;
        padding: 0 8px 0 0;
        border-bottom: none;
    }
}
