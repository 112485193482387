.block--quote {
    text-align: center;

    .wrap {
        max-width: #{533px + ($site-gutter * 2)};

        @include breakpoint(tablet) {
            max-width: #{533px + ($site-gutter--mobile * 2)};
            padding: 0 $site-gutter--mobile * 2;
        }
    }

    .wrap--wide {
        max-width: #{870px + ($site-gutter * 2)};

        @include breakpoint(tablet) {
            max-width: #{870px + ($site-gutter--mobile * 2)};
        }
    }

    .block__quote {
        @include font(25px, 33px);
        @include mediumText;
        @include margin(bottom, 5px);

        @include breakpoint(tablet) {
            @include font(21px, 28px);
            @include margin(bottom, 16px);
        }
    }

    .block__source {
        @include font(18px, 30px);

        @include breakpoint(tablet) {
            @include font(17px, 30px);
        }
    }
}
