$stats-max-items: 3;
@for $i from 1 through $stats-max-items {
    @keyframes fadeInAndOut#{$i} {
        0% {
            opacity: 0;
        }

        #{25/$i}% {
            opacity: 1;
        }

        #{75/$i}% {
            opacity: 1;
        }

        #{100/$i}% {
            opacity: 0;
        }

        100% {
            opacity: 0;
        }
    }
}

.block--stats {
    @include breakpoint(tablet) {
        margin-top: $block-vertical-padding-mobile--small;
    }

    .block__stats {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;

        @include breakpoint(tablet) {
            height: 100px;
            position: relative;
        }
    }

    .block__stat {
        @include font(16px, 26px);

        max-width: 256px;
        text-align: center;

        @include breakpoint(tablet) {
            $stats-animation-duration: 10s;
            @include font(15px, 26px);
            @include margin(bottom, 30px);

            margin-right: auto;
            margin-left: auto;
            position: absolute;
            left: auto;
            right: auto;
            opacity: 0;
            animation-iteration-count: infinite;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in-out;
            animation-duration: $stats-animation-duration;

            @for $i from 1 through $stats-max-items {
                &:nth-child(#{$i}) {
                    // Offset the start of each item, pulling back the start so the first item starts already visible
                    animation-delay: ($stats-animation-duration/$stats-max-items * $i) - $stats-animation-duration/$stats-max-items;
                }
            }
        }

        span {
            @include font(40px, 42px);
            @include semiBoldText;

            display: block;
            letter-spacing: 0.89px;
        }
    }
}
