/* basic */
.swiper-container {
    position: relative;
    overflow: hidden;

    .overlay--gallery & {
        position: absolute;
    }
}

.swiper-container,
.swiper-wrapper {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

.swiper-wrapper {
    width: max-content;

}

.swiper-slide {
    position: relative;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 18px;
    align-items: flex-start;
    cursor: grab;

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: auto;
    min-height: 100%;
    max-width: 1400px;

    .gallery--mobile & {
        @include breakpoint(tablet) {
            max-width: 100%;
        }
    }
}


/* image lazyload */
.swiper-lazy {
    display: block;
    width: 100%;
    min-height: 100%;
    opacity: 0;
    transition: all ease 300ms;
  }

.swiper-lazy-preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.swiper-lazy-loaded {
    opacity: 1;
}

.swiper-lazy-preloader:after {
    display: block;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
    width: 30px;
    height: 30px;
    border: 2px solid rgba(0, 0, 0, 0.88);
    border-radius: 50%;
    border-bottom-color: transparent;
    transform-origin: 50%;
    animation: rotate 1s infinite linear;

    .gallery--mobile &,
    .gallery--black & {
        border-color: rgba(255, 255, 255, 0.88);
        border-bottom-color: transparent;
    }
}

    @keyframes rotate {
        0% {
          transform: rotate3d(0, 0, 0, 0);
        }

        100% {
            transform: rotate3d(0, 0, 1, 360deg);
        }
    }
