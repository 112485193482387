.block--cta {

    .team-grid + & {
        margin-top: 60px;
    }

    .block__title {
        @include block-title;
    }

    .block__cta {
        margin-top: 25px;
    }

    &.block--colourway-dark {
        background-color: $c-black;
        padding-top: 78px;
        padding-bottom: 78px;

        .block__title {
            color: $c-brand;
        }

        .block__cta {

        }
    }
}
