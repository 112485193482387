.quote-card {
    .carousel & {
        max-width: calc(100% / 3);
        flex-basis: calc(100% / 3);

        @include breakpoint(phablet) {
            max-width: calc(100% - 4 * #{$site-gutter--mobile});
            flex-basis: calc(100% - 4 * #{$site-gutter--mobile});
        }

        &[data-carousel-item-viewing="true"] {
            img {
                transition: .5s filter;
                filter: none;
            }
        }
    }
}

.quote-card__wrapper {
    width: calc(1600px / 3);
    max-width: 100%;
    position: relative;

    .carousel & {
    }
}

.quote-card__image {
    @include aspect(5 / 6);
    position: relative;

    img {
        transition: .1s filter, .2s transform;
        transform: scale(110%);
        filter: grayscale(1) blur(5px);
        width: 100%;
        height: auto;
    }

    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, transparent, transparentize($c-black, .6) 60%);
        height: 100%;
        visibility: visible;

    }

    @include breakpoint(phablet) {
        &:after {
            display: none;
        }
    }
}

.quote-card__copy {
    display: none;
    opacity: 0;
    transition: .3s opacity;
    position: absolute;
    bottom: 15%;
    text-align: center;
    color: $c-white;
    left: -100px;
    right: -100px;
    z-index: 1;

    @include breakpoint(phablet) {
        left: $site-gutter--mobile;
        right: $site-gutter--mobile;

        padding-top: 25px;
        padding-left: 10px;
        padding-right: 10px;

        position: relative;
        bottom: auto;
        color: $c-black;
        left: auto;
        right: auto;
    }

    .carousel--scrolling & {
        display: block;
        opacity: 0;
    }

    .carousel:not(.carousel--scrolling) [data-carousel-item-active="true"] & {
        display: block;
    }

    .carousel:not(.carousel--scrolling) [data-carousel-item-viewing="true"] & {
        opacity: 1;
    }
}

.quote-card__quote {
    @include font(25px, 30px);

    @include breakpoint(tablet) {
        @include font(21px, 28px);
    }

    @include breakpoint(phablet) {
        letter-spacing: 0.4px;
    }
}

.quote-card__person {
    @include font(18px, 30px);
    letter-spacing: 0.3px;

    @include breakpoint(tablet) {
        @include font(17px, 25px);
    }

    @include breakpoint(phablet) {
        letter-spacing: 0.5px;
        padding-top: 20px;
    }
}
