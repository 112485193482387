.blocks {
  margin-bottom: 160px;
}
@media screen and (max-width: 62.4375em) {
  .blocks {
    margin-bottom: 65px;
  }
}
.header--static ~ #main .blocks:first-child .block:first-child {
  margin-top: 0;
}

.block {
  margin-top: 120px;
}
@media screen and (max-width: 62.4375em) {
  .block:first-child {
    margin-top: 65px;
  }
}
@media screen and (max-width: 62.4375em) {
  .block {
    margin-top: 65px;
  }
}
.block:last-child {
  margin-bottom: 160px;
}
@media screen and (max-width: 62.4375em) {
  .block:last-child {
    margin-bottom: 65px;
  }
}
.wrap .block .wrap {
  max-width: none;
  padding: 0;
}

.blocks--tight-bottom {
  margin-bottom: 90px;
}
.blocks--tight-bottom .block:last-child {
  margin-bottom: 90px;
}

.block--banner, .block--jobs {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: relative;
  color: #FFFFFF;
}
.block--banner .block__image, .block--jobs .block__image {
  overflow: hidden;
  background: #000000;
  position: relative;
}
.block--banner .block__image:before, .block--jobs .block__image:before {
  float: left;
  content: "";
  padding-bottom: 44.4444444444%;
  margin-bottom: 0px;
}
.block--banner .block__image:after, .block--jobs .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 42.5em) {
  .block--banner .block__image, .block--jobs .block__image {
    overflow: hidden;
  }
  .block--banner .block__image:before, .block--jobs .block__image:before {
    float: left;
    content: "";
    padding-bottom: 120%;
    margin-bottom: 0px;
  }
  .block--banner .block__image:after, .block--jobs .block__image:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
    visibility: hidden;
  }
}
.block--banner .block__image img, .block--jobs .block__image img {
  width: 100%;
  max-width: 100%;
  height: auto;
  opacity: 0.75;
}
.block--banner .block__image--tint-dark:after, .block--jobs .block__image--tint-dark:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  visibility: visible;
  height: auto;
}
.block--banner .block__content, .block--jobs .block__content {
  position: absolute;
  top: 84px;
  left: 50%;
  max-width: 393px;
  text-align: center;
  transform: translateX(-50%);
  z-index: 2;
}
@media screen and (max-width: 62.4375em) {
  .block--banner .block__content, .block--jobs .block__content {
    top: 54px;
    max-width: 500px;
    left: 30px;
    right: 30px;
    transform: none;
    margin: 0 auto;
  }
}
.block--banner .block__title, .block--jobs .block__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.32;
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: 0.8px;
}
.block--banner .block__title:last-child, .block--jobs .block__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--banner .block__title, .block--jobs .block__title {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.3333333333;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--banner .block__title, .block--jobs .block__title {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.4285714286;
    margin-bottom: 14px;
    letter-spacing: 0.4px;
  }
  .block--banner .block__title:last-child, .block--jobs .block__title:last-child {
    margin-bottom: 0;
  }
}
.block--banner .block__text, .block--jobs .block__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  margin-bottom: 14px;
}
.block--banner .block__text:last-child, .block--jobs .block__text:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--banner .block__text, .block--jobs .block__text {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.7333333333;
    margin-bottom: 10px;
  }
  .block--banner .block__text:last-child, .block--jobs .block__text:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 62.4375em) {
  .block--inline-image, .block--timeline-event {
    margin-top: 50px;
  }
}
.block--inline-image .block__images, .block--timeline-event .block__images {
  position: relative;
}
.block--inline-image .blocks__text, .block--timeline-event .blocks__text {
  hyphens: none;
}
.block--inline-image .block__images--quad, .block--timeline-event .block__images--quad {
  overflow: hidden;
}
.block--inline-image .block__images--quad:before, .block--timeline-event .block__images--quad:before {
  float: left;
  content: "";
  padding-bottom: 93.2565789474%;
  margin-bottom: 0px;
}
.block--inline-image .block__images--quad:after, .block--timeline-event .block__images--quad:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 62.4375em) {
  .block--inline-image .block__images--quad, .block--timeline-event .block__images--quad {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 42.5em) {
  .block--inline-image .block__images--quad, .block--timeline-event .block__images--quad {
    overflow: hidden;
  }
  .block--inline-image .block__images--quad:before, .block--timeline-event .block__images--quad:before {
    float: left;
    content: "";
    padding-bottom: 111.9402985075%;
    margin-bottom: 0px;
  }
  .block--inline-image .block__images--quad:after, .block--timeline-event .block__images--quad:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
    visibility: hidden;
  }
}
.block--inline-image .block__image, .block--timeline-event .block__image {
  overflow: hidden;
}
.block--inline-image .block__image:before, .block--timeline-event .block__image:before {
  float: left;
  content: "";
  padding-bottom: 75%;
  margin-bottom: 0px;
}
.block--inline-image .block__image:after, .block--timeline-event .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 62.4375em) {
  .block--inline-image .block__image, .block--timeline-event .block__image {
    overflow: hidden;
  }
  .block--inline-image .block__image:before, .block--timeline-event .block__image:before {
    float: left;
    content: "";
    padding-bottom: 60.2339181287%;
    margin-bottom: 0px;
  }
  .block--inline-image .block__image:after, .block--timeline-event .block__image:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
    visibility: hidden;
  }
}
.block--inline-image .block__image img, .block--timeline-event .block__image img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
@media screen and (max-width: 62.4375em) {
  .block--inline-image .block__image--alt, .block--timeline-event .block__image--alt {
    overflow: hidden;
    width: 78%;
  }
  .block--inline-image .block__image--alt:before, .block--timeline-event .block__image--alt:before {
    float: left;
    content: "";
    padding-bottom: 100%;
    margin-bottom: 0px;
  }
  .block--inline-image .block__image--alt:after, .block--timeline-event .block__image--alt:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
    visibility: hidden;
  }
}
.block--inline-image .block__image--offset, .block--timeline-event .block__image--offset {
  overflow: hidden;
  position: absolute;
  top: 50%;
  right: 0;
  width: 48%;
  transform: translate(25%, -50%);
}
.block--inline-image .block__image--offset:before, .block--timeline-event .block__image--offset:before {
  float: left;
  content: "";
  padding-bottom: 100%;
  margin-bottom: 0px;
}
.block--inline-image .block__image--offset:after, .block--timeline-event .block__image--offset:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 62.4375em) {
  .block--inline-image .block__image--offset, .block--timeline-event .block__image--offset {
    transform: translate(0, -50%);
  }
}
.block--inline-image.block--image-layout-squares .block__images, .block--image-layout-squares.block--timeline-event .block__images {
  padding-bottom: 60px;
}
@media screen and (max-width: 62.4375em) {
  .block--inline-image.block--image-layout-squares .block__images, .block--image-layout-squares.block--timeline-event .block__images {
    transform: translateY(11%);
  }
}
.block--inline-image.block--image-layout-squares .block__image--alt, .block--image-layout-squares.block--timeline-event .block__image--alt {
  overflow: hidden;
  width: 65%;
  margin-left: auto;
}
.block--inline-image.block--image-layout-squares .block__image--alt:before, .block--image-layout-squares.block--timeline-event .block__image--alt:before {
  float: left;
  content: "";
  padding-bottom: 100%;
  margin-bottom: 0px;
}
.block--inline-image.block--image-layout-squares .block__image--alt:after, .block--image-layout-squares.block--timeline-event .block__image--alt:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.block--inline-image.block--image-layout-squares .block__image--offset, .block--image-layout-squares.block--timeline-event .block__image--offset {
  overflow: hidden;
  top: auto;
  bottom: 0;
  right: auto;
  left: 0;
  width: 48%;
  transform: none;
}
.block--inline-image.block--image-layout-squares .block__image--offset:before, .block--image-layout-squares.block--timeline-event .block__image--offset:before {
  float: left;
  content: "";
  padding-bottom: 100%;
  margin-bottom: 0px;
}
.block--inline-image.block--image-layout-squares .block__image--offset:after, .block--image-layout-squares.block--timeline-event .block__image--offset:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 62.4375em) {
  .block--inline-image.block--image-layout-squares .block__image--offset, .block--image-layout-squares.block--timeline-event .block__image--offset {
    transform: translate(0, -50%);
  }
}
@media screen and (max-width: 62.4375em) {
  .block--inline-image.block--image-layout-squares .wrap .grid, .block--image-layout-squares.block--timeline-event .wrap .grid {
    display: flex;
    flex-direction: column-reverse;
  }
  .block--inline-image.block--image-layout-squares .block__images, .block--image-layout-squares.block--timeline-event .block__images {
    padding-bottom: 0;
  }
  .block--inline-image.block--image-layout-squares .block__content, .block--image-layout-squares.block--timeline-event .block__content {
    padding-top: 0;
    padding-bottom: 30px;
  }
}
.block--inline-image .block__image--quad, .block--timeline-event .block__image--quad {
  overflow: hidden;
  position: absolute;
}
.block--inline-image .block__image--quad:before, .block--timeline-event .block__image--quad:before {
  float: left;
  content: "";
  padding-bottom: 100%;
  margin-bottom: 0px;
}
.block--inline-image .block__image--quad:after, .block--timeline-event .block__image--quad:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 42.5em) {
  .block--inline-image .block__image--quad, .block--timeline-event .block__image--quad {
    padding: 0;
  }
}
.block--inline-image .block__image--quad-1, .block--timeline-event .block__image--quad-1 {
  top: 10%;
  left: 0;
  width: 50%;
  padding: 0 15px 15px 0;
}
@media screen and (max-width: 42.5em) {
  .block--inline-image .block__image--quad-1, .block--timeline-event .block__image--quad-1 {
    padding: 0 7.5px 7.5px 0;
  }
}
.block--inline-image .block__image--quad-2, .block--timeline-event .block__image--quad-2 {
  top: 0;
  right: 16.6666666667%;
  width: 33.3333333333%;
  padding: 0 0 15px 15px;
}
@media screen and (max-width: 42.5em) {
  .block--inline-image .block__image--quad-2, .block--timeline-event .block__image--quad-2 {
    padding: 0 0 7.5px 7.5px;
    width: 50%;
    right: 0;
  }
}
.block--inline-image .block__image--quad-3, .block--timeline-event .block__image--quad-3 {
  left: 16.6666666667%;
  bottom: 0;
  width: 33.3333333333%;
  padding: 15px 15px 0 0;
}
@media screen and (max-width: 42.5em) {
  .block--inline-image .block__image--quad-3, .block--timeline-event .block__image--quad-3 {
    padding: 7.5px 7.5px 0 0;
    width: 50%;
    left: 0;
  }
}
.block--inline-image .block__image--quad-4, .block--timeline-event .block__image--quad-4 {
  right: 0;
  width: 50%;
  bottom: 10%;
  padding: 15px 0 0 15px;
}
@media screen and (max-width: 42.5em) {
  .block--inline-image .block__image--quad-4, .block--timeline-event .block__image--quad-4 {
    padding: 7.5px 0 0 7.5px;
  }
}
.block--inline-image .block__content, .block--timeline-event .block__content {
  padding: 0 105px;
}
@media screen and (max-width: 62.4375em) {
  .block--inline-image .block__content .cta, .block--timeline-event .block__content .cta {
    display: table;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--inline-image .block__content .cta + .cta, .block--timeline-event .block__content .cta + .cta {
    margin-left: 0;
    margin-top: 15px;
  }
}
@media screen and (max-width: 75em) {
  .block--inline-image .block__content, .block--timeline-event .block__content {
    padding: 0 50px;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--inline-image .block__content, .block--timeline-event .block__content {
    padding: 0;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--inline-image .block__content--twin, .block--timeline-event .block__content--twin {
    padding-top: 10px;
    text-align: left;
  }
}
.block--inline-image .block__content--twin .block__text, .block--timeline-event .block__content--twin .block__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
}
@media screen and (max-width: 62.4375em) {
  .block--inline-image .block__content--twin .block__text, .block--timeline-event .block__content--twin .block__text {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.7333333333;
    letter-spacing: 0.47px;
  }
}
.block--inline-image .block__title, .block--timeline-event .block__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.32;
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: 0.8px;
}
.block--inline-image .block__title:last-child, .block--timeline-event .block__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--inline-image .block__title, .block--timeline-event .block__title {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.3333333333;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--inline-image .block__title, .block--timeline-event .block__title {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.3333333333;
    margin-bottom: 16px;
  }
  .block--inline-image .block__title:last-child, .block--timeline-event .block__title:last-child {
    margin-bottom: 0;
  }
}
.block--inline-image .block__text, .block--timeline-event .block__text {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3888888889;
  margin-bottom: 10px;
}
.block--inline-image .block__text:last-child, .block--timeline-event .block__text:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--inline-image .block__text, .block--timeline-event .block__text {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1.4705882353;
    margin-bottom: 16px;
  }
  .block--inline-image .block__text:last-child, .block--timeline-event .block__text:last-child {
    margin-bottom: 0;
  }
}
.block--inline-image .block__text + .block__body, .block--timeline-event .block__text + .block__body {
  margin-top: 40px;
}
.block--inline-image .cta + .cta, .block--timeline-event .cta + .cta {
  margin-left: 35px;
}

@media screen and (max-width: 62.4375em) {
  .block--intro .page-intro__image {
    margin-top: 30px;
  }
}
.block--intro.block--images-1 {
  margin-top: 70px;
}

@media screen and (max-width: 62.4375em) {
  .block--logos {
    overflow: hidden;
    margin-top: 50px;
  }
}
.block--logos .block__logos {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  max-width: 800px;
  margin: 0 auto;
}
@media screen and (max-width: 62.4375em) {
  .block--logos .block__logos {
    grid-template-columns: 2fr 1fr 1fr;
  }
}
.block--logos .block__image {
  display: grid;
  place-items: center;
}
.block--logos .block__image svg {
  display: block;
  max-width: 100%;
  object-fit: contain;
}
.block--logos .block__image:nth-child(1) {
  order: 1;
}
.block--logos .block__image:nth-child(2) {
  order: 2;
}
.block--logos .block__image:nth-child(3) {
  order: 3;
}
.block--logos .block__image:nth-child(4) {
  order: 4;
}
.block--logos .block__image:nth-child(5) {
  order: 5;
}
.block--logos .block__image:nth-child(6) {
  order: 6;
}
.block--logos .block__image:nth-child(7) {
  order: 7;
}
.block--logos .block__image:nth-child(8) {
  order: 8;
}
@media screen and (max-width: 62.4375em) {
  .block--logos .block__image:nth-child(2) {
    margin: 0 -20px;
  }
  .block--logos .block__image:nth-child(5), .block--logos .block__image:nth-child(8) {
    margin-top: -30px;
  }
  .block--logos .block__image:nth-child(7) {
    order: 1;
    margin: 0 -20px;
  }
  .block--logos .block__image:nth-child(8) {
    grid-column: 2/4;
  }
  .block--logos .block__image:nth-child(3), .block--logos .block__image:nth-child(4), .block--logos .block__image:nth-child(6) {
    display: none;
  }
}

.block--quote {
  text-align: center;
}
.block--quote .wrap {
  max-width: 693px;
}
@media screen and (max-width: 62.4375em) {
  .block--quote .wrap {
    max-width: 565px;
    padding: 0 32px;
  }
}
.block--quote .wrap--wide {
  max-width: 1030px;
}
@media screen and (max-width: 62.4375em) {
  .block--quote .wrap--wide {
    max-width: 902px;
  }
}
.block--quote .block__quote {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.32;
  font-weight: 500;
  margin-bottom: 5px;
}
.block--quote .block__quote:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--quote .block__quote {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.3333333333;
    margin-bottom: 16px;
  }
  .block--quote .block__quote:last-child {
    margin-bottom: 0;
  }
}
.block--quote .block__source {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.6666666667;
}
@media screen and (max-width: 62.4375em) {
  .block--quote .block__source {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1.7647058824;
  }
}

.block--principles .block__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 38px;
  text-align: center;
}
.block--principles .block__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--principles .block__title {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.5714285714;
    margin-bottom: 25px;
  }
  .block--principles .block__title:last-child {
    margin-bottom: 0;
  }
}
.block--principles .block__principles {
  display: -ms-grid;
  display: grid;
  max-width: 840px;
  margin-right: auto;
  margin-bottom: -46px;
  margin-left: auto;
}
@media screen and (max-width: 62.4375em) {
  .block--principles .block__principles {
    display: block;
    margin-bottom: -22px;
  }
}
.block--principles .block__principles[data-principles="1"] {
  -ms-grid-rows: 1 [1fr];
  grid-template-rows: repeat(1fr, 1);
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.block--principles .block__principles[data-principles="1"] > :nth-child(-n+1) {
  -ms-grid-column: 1;
  grid-column: 1;
}
.block--principles .block__principles[data-principles="1"] > :nth-child(n+2) {
  -ms-grid-column: 2;
  grid-column: 2;
}
.block--principles .block__principles[data-principles="1"] > :nth-child(1n+1) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/span 1;
}
.block--principles .block__principles[data-principles="2"] {
  -ms-grid-rows: 1 [1fr];
  grid-template-rows: repeat(1fr, 1);
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.block--principles .block__principles[data-principles="2"] > :nth-child(-n+1) {
  -ms-grid-column: 1;
  grid-column: 1;
}
.block--principles .block__principles[data-principles="2"] > :nth-child(n+2) {
  -ms-grid-column: 2;
  grid-column: 2;
}
.block--principles .block__principles[data-principles="2"] > :nth-child(1n+1) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/span 1;
}
.block--principles .block__principles[data-principles="3"] {
  -ms-grid-rows: 2 [1fr];
  grid-template-rows: repeat(1fr, 2);
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.block--principles .block__principles[data-principles="3"] > :nth-child(-n+2) {
  -ms-grid-column: 1;
  grid-column: 1;
}
.block--principles .block__principles[data-principles="3"] > :nth-child(n+3) {
  -ms-grid-column: 2;
  grid-column: 2;
}
.block--principles .block__principles[data-principles="3"] > :nth-child(2n+1) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/span 1;
}
.block--principles .block__principles[data-principles="3"] > :nth-child(2n+2) {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/span 1;
}
.block--principles .block__principles[data-principles="4"] {
  -ms-grid-rows: 2 [1fr];
  grid-template-rows: repeat(1fr, 2);
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.block--principles .block__principles[data-principles="4"] > :nth-child(-n+2) {
  -ms-grid-column: 1;
  grid-column: 1;
}
.block--principles .block__principles[data-principles="4"] > :nth-child(n+3) {
  -ms-grid-column: 2;
  grid-column: 2;
}
.block--principles .block__principles[data-principles="4"] > :nth-child(2n+1) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/span 1;
}
.block--principles .block__principles[data-principles="4"] > :nth-child(2n+2) {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/span 1;
}
.block--principles .block__principles[data-principles="5"] {
  -ms-grid-rows: 3 [1fr];
  grid-template-rows: repeat(1fr, 3);
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.block--principles .block__principles[data-principles="5"] > :nth-child(-n+3) {
  -ms-grid-column: 1;
  grid-column: 1;
}
.block--principles .block__principles[data-principles="5"] > :nth-child(n+4) {
  -ms-grid-column: 2;
  grid-column: 2;
}
.block--principles .block__principles[data-principles="5"] > :nth-child(3n+1) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/span 1;
}
.block--principles .block__principles[data-principles="5"] > :nth-child(3n+2) {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/span 1;
}
.block--principles .block__principles[data-principles="5"] > :nth-child(3n+3) {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3/span 1;
}
.block--principles .block__principles[data-principles="6"] {
  -ms-grid-rows: 3 [1fr];
  grid-template-rows: repeat(1fr, 3);
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.block--principles .block__principles[data-principles="6"] > :nth-child(-n+3) {
  -ms-grid-column: 1;
  grid-column: 1;
}
.block--principles .block__principles[data-principles="6"] > :nth-child(n+4) {
  -ms-grid-column: 2;
  grid-column: 2;
}
.block--principles .block__principles[data-principles="6"] > :nth-child(3n+1) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/span 1;
}
.block--principles .block__principles[data-principles="6"] > :nth-child(3n+2) {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/span 1;
}
.block--principles .block__principles[data-principles="6"] > :nth-child(3n+3) {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3/span 1;
}
.block--principles .block__principles[data-principles="7"] {
  -ms-grid-rows: 4 [1fr];
  grid-template-rows: repeat(1fr, 4);
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.block--principles .block__principles[data-principles="7"] > :nth-child(-n+4) {
  -ms-grid-column: 1;
  grid-column: 1;
}
.block--principles .block__principles[data-principles="7"] > :nth-child(n+5) {
  -ms-grid-column: 2;
  grid-column: 2;
}
.block--principles .block__principles[data-principles="7"] > :nth-child(4n+1) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/span 1;
}
.block--principles .block__principles[data-principles="7"] > :nth-child(4n+2) {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/span 1;
}
.block--principles .block__principles[data-principles="7"] > :nth-child(4n+3) {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3/span 1;
}
.block--principles .block__principles[data-principles="7"] > :nth-child(4n+4) {
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
  grid-row: 4/span 1;
}
.block--principles .block__principles[data-principles="8"] {
  -ms-grid-rows: 4 [1fr];
  grid-template-rows: repeat(1fr, 4);
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.block--principles .block__principles[data-principles="8"] > :nth-child(-n+4) {
  -ms-grid-column: 1;
  grid-column: 1;
}
.block--principles .block__principles[data-principles="8"] > :nth-child(n+5) {
  -ms-grid-column: 2;
  grid-column: 2;
}
.block--principles .block__principles[data-principles="8"] > :nth-child(4n+1) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/span 1;
}
.block--principles .block__principles[data-principles="8"] > :nth-child(4n+2) {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/span 1;
}
.block--principles .block__principles[data-principles="8"] > :nth-child(4n+3) {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3/span 1;
}
.block--principles .block__principles[data-principles="8"] > :nth-child(4n+4) {
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
  grid-row: 4/span 1;
}
.block--principles .block__principles[data-principles="9"] {
  -ms-grid-rows: 5 [1fr];
  grid-template-rows: repeat(1fr, 5);
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.block--principles .block__principles[data-principles="9"] > :nth-child(-n+5) {
  -ms-grid-column: 1;
  grid-column: 1;
}
.block--principles .block__principles[data-principles="9"] > :nth-child(n+6) {
  -ms-grid-column: 2;
  grid-column: 2;
}
.block--principles .block__principles[data-principles="9"] > :nth-child(5n+1) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/span 1;
}
.block--principles .block__principles[data-principles="9"] > :nth-child(5n+2) {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/span 1;
}
.block--principles .block__principles[data-principles="9"] > :nth-child(5n+3) {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3/span 1;
}
.block--principles .block__principles[data-principles="9"] > :nth-child(5n+4) {
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
  grid-row: 4/span 1;
}
.block--principles .block__principles[data-principles="9"] > :nth-child(5n+5) {
  -ms-grid-row: 5;
  -ms-grid-row-span: 1;
  grid-row: 5/span 1;
}
.block--principles .block__principles > * {
  padding-right: 40px;
  padding-bottom: 46px;
}
@media screen and (max-width: 62.4375em) {
  .block--principles .block__principles > * {
    padding-right: 0;
    padding-bottom: 22px;
  }
}

@keyframes fadeInAndOut1 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeInAndOut2 {
  0% {
    opacity: 0;
  }
  12.5% {
    opacity: 1;
  }
  37.5% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeInAndOut3 {
  0% {
    opacity: 0;
  }
  8.3333333333% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  33.3333333333% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--stats {
    margin-top: 50px;
  }
}
.block--stats .block__stats {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
@media screen and (max-width: 62.4375em) {
  .block--stats .block__stats {
    height: 100px;
    position: relative;
  }
}
.block--stats .block__stat {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  max-width: 256px;
  text-align: center;
}
@media screen and (max-width: 62.4375em) {
  .block--stats .block__stat {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.7333333333;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    left: auto;
    right: auto;
    opacity: 0;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-duration: 10s;
  }
  .block--stats .block__stat:last-child {
    margin-bottom: 0;
  }
  .block--stats .block__stat:nth-child(1) {
    animation-delay: 0s;
  }
  .block--stats .block__stat:nth-child(2) {
    animation-delay: 3.3333333333s;
  }
  .block--stats .block__stat:nth-child(3) {
    animation-delay: 6.6666666667s;
  }
}
.block--stats .block__stat span {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 1.05;
  font-weight: 600;
  display: block;
  letter-spacing: 0.89px;
}

.block--site-links .block__image,
.block--other-services .block__image {
  overflow: hidden;
}
.block--site-links .block__image:before,
.block--other-services .block__image:before {
  float: left;
  content: "";
  padding-bottom: 76.0330578512%;
  margin-bottom: 0px;
}
.block--site-links .block__image:after,
.block--other-services .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.block--site-links .block__image img,
.block--other-services .block__image img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.block--site-links .block__content,
.block--other-services .block__content {
  text-align: left;
}
.block--site-links .block__title,
.block--other-services .block__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 38px;
}
.block--site-links .block__title:last-child,
.block--other-services .block__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--site-links .block__title,
  .block--other-services .block__title {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.3333333333;
    margin-bottom: 16px;
  }
  .block--site-links .block__title:last-child,
  .block--other-services .block__title:last-child {
    margin-bottom: 0;
  }
}
.block--site-links .block__links,
.block--other-services .block__links {
  display: flex;
  flex-wrap: wrap;
  margin-left: -25px;
}
@media screen and (max-width: 62.4375em) {
  .block--site-links .block__links,
  .block--other-services .block__links {
    flex-wrap: nowrap;
    width: calc(100% + 32px);
    margin-right: -16px;
    margin-left: -16px;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 20px;
    overflow: auto;
  }
}
.block--site-links .block__link-container,
.block--other-services .block__link-container {
  width: calc(50% - 50px);
  margin: 0 25px 44px;
}
@media screen and (max-width: 62.4375em) {
  .block--site-links .block__link-container,
  .block--other-services .block__link-container {
    flex-grow: 0;
    flex-shrink: 0;
    width: 210px;
    margin: 0 40px 0 0;
  }
}
.block--site-links .block__link,
.block--other-services .block__link {
  display: block;
  color: inherit;
  text-decoration: none;
}
.block--site-links .block__link:hover svg,
.block--other-services .block__link:hover svg {
  fill: #00ABCE;
  transform: translateX(5px);
}
@media screen and (max-width: 62.4375em) {
  .block--site-links .block__link:hover svg,
  .block--other-services .block__link:hover svg {
    fill: inherit;
  }
}
.block--site-links .block__link:hover .block__subtitle,
.block--other-services .block__link:hover .block__subtitle {
  color: #00ABCE;
}
@media screen and (max-width: 62.4375em) {
  .block--site-links .block__link:hover .block__subtitle,
  .block--other-services .block__link:hover .block__subtitle {
    color: inherit;
  }
}
.block--site-links .block__subtitle,
.block--other-services .block__subtitle {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.6666666667;
  font-weight: 500;
  margin-bottom: 3px;
  transition: 0.2s color ease;
}
.block--site-links .block__subtitle:last-child,
.block--other-services .block__subtitle:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--site-links .block__subtitle,
  .block--other-services .block__subtitle {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1.7647058824;
  }
}
.block--site-links .block__subtitle svg,
.block--other-services .block__subtitle svg {
  margin-left: 10px;
  transition: transform 0.2s ease;
}
.block--site-links .block__text,
.block--other-services .block__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
}
@media screen and (max-width: 62.4375em) {
  .block--site-links .block__text,
  .block--other-services .block__text {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.7333333333;
  }
}

.block--projects {
  margin: 90px 0;
}
@media screen and (max-width: 62.4375em) {
  .block--projects {
    margin: 60px 0;
  }
}
.block--projects .carousel {
  margin-bottom: 30px;
}
.block--projects .carousel:last-child {
  margin-bottom: 0;
}
.block--projects .block__footer {
  text-align: center;
}

.block--projects-list .block__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.32;
  font-weight: 500;
  margin-bottom: 50px;
}
@media screen and (max-width: 62.4375em) {
  .block--projects-list .block__title {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1.6470588235;
    margin-bottom: 38px;
  }
}

.block--screenshot-gallery,
.block--gallery {
  margin-top: 65px;
}
@media screen and (max-width: 62.4375em) {
  .block--screenshot-gallery,
  .block--gallery {
    margin-top: 50px;
  }
}
.block--screenshot-gallery .carousel,
.block--gallery .carousel {
  margin-bottom: 60px;
}
.block--screenshot-gallery .carousel:last-child,
.block--gallery .carousel:last-child {
  margin-bottom: 0;
}
.block--screenshot-gallery .block__footer,
.block--gallery .block__footer {
  text-align: center;
}
@media screen and (max-width: 62.4375em) {
  .block--screenshot-gallery .block__footer,
  .block--gallery .block__footer {
    margin-top: 30px;
  }
}

.block--features .block__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.32;
  font-weight: 500;
  margin-bottom: 70px;
  text-align: center;
}
.block--features .block__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--features .block__title {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1.6470588235;
    margin-bottom: 30px;
    letter-spacing: 0.3px;
  }
  .block--features .block__title:last-child {
    margin-bottom: 0;
  }
}
.block--features .block__title--small-margin {
  margin-bottom: 25px;
}
.block--features .block__title--small-margin:last-child {
  margin-bottom: 0;
}
.block--features .block__content {
  max-width: 820px;
  margin: 0 auto;
}
.block--features .block__content--wide {
  max-width: 1024px;
}
.block--features .grid {
  margin-bottom: -70px;
}
@media screen and (max-width: 62.4375em) {
  .block--features .grid {
    margin-bottom: -40px;
  }
}
.block--features .grid > * {
  margin-bottom: 70px;
}
@media screen and (max-width: 62.4375em) {
  .block--features .grid > * {
    margin-bottom: 40px;
  }
}

.block--richtext .section-intro {
  margin-bottom: 15px;
}
@media screen and (max-width: 62.4375em) {
  .block--richtext {
    margin: 60px 0;
  }
}

.block--table {
  margin: 90px 0;
}
@media screen and (max-width: 62.4375em) {
  .block--table {
    margin: 60px 0;
  }
}
.block--table table {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7857142857;
  width: 100%;
  letter-spacing: 0.4px;
}
.block--table th,
.block--table td {
  padding: 9px 15px 6px;
  border: 1px solid #DDDDDD;
}
.block--table th {
  background: #F6F6F6;
}
.block--table a {
  color: inherit;
  text-decoration: underline;
}

.block--services .wrap {
  max-width: 1210px;
}
@media screen and (max-width: 34.375em) {
  .block--services .wrap {
    max-width: 565px;
  }
}
.block--services .block__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.32;
  font-weight: 500;
  margin-bottom: 53px;
  text-align: center;
}
.block--services .block__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 34.375em) {
  .block--services .block__title {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.4285714286;
    margin-bottom: 26px;
    text-align: left;
  }
  .block--services .block__title:last-child {
    margin-bottom: 0;
  }
}
.block--services .block__services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (max-width: 34.375em) {
  .block--services .block__services {
    display: block;
    margin: 0;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--services .block__services .carousel__container {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 42.5em) {
  .block--services .block__services .carousel__container {
    flex-wrap: nowrap;
  }
}

.block--approaches .block__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 45px;
  text-align: center;
}
.block--approaches .block__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--approaches .block__title {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.5714285714;
    margin-bottom: 25px;
  }
  .block--approaches .block__title:last-child {
    margin-bottom: 0;
  }
}
.block--approaches .block__approaches {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (max-width: 34.375em) {
  .block--approaches .block__approaches {
    display: block;
    margin: 0;
  }
}
.block--approaches .block__approaches--even .carousel__container {
  grid-template-columns: repeat(2, 1fr);
}
.block--approaches .block__approaches--odd .carousel__container {
  grid-template-columns: repeat(3, 1fr);
}
.block--approaches .block__approaches .carousel__container {
  display: grid;
  flex-wrap: wrap;
  gap: 40px 10px;
}
@media screen and (max-width: 62.4375em) {
  .block--approaches .block__approaches .carousel__container {
    grid-template-columns: repeat(2, minmax(auto, 460px));
  }
  .block--approaches .block__approaches .carousel__container > *:last-child:nth-child(2n-1) {
    grid-column-start: 1;
    grid-column-end: 4;
    max-width: 460px;
    margin: auto;
  }
}
@media screen and (max-width: 42.5em) {
  .block--approaches .block__approaches .carousel__container {
    display: flex;
    flex-wrap: nowrap;
  }
}
.block--approaches .block__approaches--grid > * {
  margin: 0 30px;
}
@media screen and (max-width: 62.4375em) {
  .block--approaches .block__approaches--grid > * {
    margin-bottom: 25px;
    margin-right: 0;
    margin-left: 0;
  }
  .block--approaches .block__approaches--grid > *:last-child {
    margin-bottom: 0;
  }
}
.block--approaches .block__approaches--grid > *:not(:nth-last-child(2)):not(:last-child) {
  margin-bottom: 45px;
}
@media screen and (max-width: 62.4375em) {
  .block--approaches .block__approaches--grid > *:not(:nth-last-child(2)):not(:last-child) {
    margin-bottom: 25px;
    margin-right: 0;
    margin-left: 0;
  }
  .block--approaches .block__approaches--grid > *:not(:nth-last-child(2)):not(:last-child):last-child {
    margin-bottom: 0;
  }
}

.block--fit {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  padding: 130px 0;
  color: #FFFFFF;
  background: #000000;
  text-align: center;
}
@media screen and (max-width: 62.4375em) {
  .block--fit {
    padding: 70px 0;
  }
}
.block--fit .wrap {
  max-width: 1100px;
}
@media screen and (max-width: 62.4375em) {
  .block--fit .wrap {
    max-width: 565px;
  }
}
.block--fit .block__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.32;
  font-weight: 500;
  margin-bottom: 50px;
}
.block--fit .block__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--fit .block__title {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.5714285714;
    margin-bottom: 30px;
    letter-spacing: 0.4px;
  }
  .block--fit .block__title:last-child {
    margin-bottom: 0;
  }
}
.block--fit .block__text {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.block--fit .block__text:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--fit .block__text {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.4285714286;
    margin-bottom: 40px;
    letter-spacing: 0.4px;
  }
  .block--fit .block__text:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 42.5em) {
  .block--fit .block__text {
    display: block;
  }
}
.block--fit .field {
  margin-bottom: 20px;
}
.block--fit .field label {
  white-space: nowrap;
}
@media screen and (max-width: 62.4375em) {
  .block--fit .field {
    margin-bottom: 25px;
  }
  .block--fit .field:last-child {
    margin-bottom: 0;
  }
}
.block--fit .field + .field {
  margin-left: 5px;
}
@media screen and (max-width: 62.4375em) {
  .block--fit .field + .field {
    margin-left: 0;
  }
}
.block--fit .field {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.block--fit .field:last-child {
  flex-basis: 100%;
}
@media screen and (max-width: 42.5em) {
  .block--fit .select-replace {
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0.65ch;
  }
}
.block--fit .select-replace:before {
  margin-left: 10px;
  color: #00ABCE;
}
@media screen and (max-width: 42.5em) {
  .block--fit .select-replace:before {
    margin-left: 0;
    padding-right: 10px;
    white-space: nowrap;
  }
}
.block--fit .select-replace svg {
  order: 2;
  fill: #00ABCE;
  width: 15px;
  height: 10px;
  vertical-align: middle;
}
@media screen and (max-width: 42.5em) {
  .block--fit .select-replace label {
    white-space: nowrap;
    padding-right: 0.65ch;
  }
}
.block--fit .select-replace select {
  color: initial;
  background: initial;
}

.block--definitions {
  margin: 140px 0 120px;
}
@media screen and (max-width: 62.4375em) {
  .block--definitions {
    margin: 60px 0;
  }
}
.block--definitions .wrap {
  max-width: 860px;
}
@media screen and (max-width: 62.4375em) {
  .block--definitions .wrap {
    max-width: none;
  }
}
.block--definitions .block__list {
  margin-bottom: 30px;
  display: flex;
}
.block--definitions .block__list:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--definitions .block__list {
    margin-bottom: 50px;
    display: block;
  }
  .block--definitions .block__list:last-child {
    margin-bottom: 0;
  }
}
.block--definitions .block__list dt {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.2;
  font-weight: 500;
  flex: 0 0 220px;
  margin-right: 30px;
  text-align: right;
}
@media screen and (max-width: 62.4375em) {
  .block--definitions .block__list dt {
    margin-bottom: 7px;
    text-align: left;
  }
  .block--definitions .block__list dt:last-child {
    margin-bottom: 0;
  }
}
.block--definitions .block__list dd {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
}
@media screen and (max-width: 62.4375em) {
  .block--definitions .block__list dd {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.7333333333;
  }
}

@media screen and (max-width: 62.4375em) {
  .block--clients {
    margin-top: 50px;
  }
}

.block--map .block__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.32;
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: 0.8px;
}
.block--map .block__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--map .block__title {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.3333333333;
  }
}
.block--map .block__map {
  overflow: hidden;
}
.block--map .block__map:before {
  float: left;
  content: "";
  padding-bottom: 75%;
  margin-bottom: 0px;
}
.block--map .block__map:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.block--map address {
  margin-bottom: 10px;
}

.team-grid + .block--cta {
  margin-top: 60px;
}
.block--cta .block__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.32;
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: 0.8px;
}
.block--cta .block__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--cta .block__title {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.3333333333;
  }
}
.block--cta .block__cta {
  margin-top: 25px;
}
.block--cta.block--colourway-dark {
  background-color: #000000;
  padding-top: 78px;
  padding-bottom: 78px;
}
.block--cta.block--colourway-dark .block__title {
  color: #00ABCE;
}
.block--jobs .block__image {
  overflow: hidden;
}
.block--jobs .block__image:before {
  float: left;
  content: "";
  padding-bottom: 27.7777777778%;
  margin-bottom: 0px;
}
.block--jobs .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 42.5em) {
  .block--jobs .block__image {
    overflow: hidden;
  }
  .block--jobs .block__image:before {
    float: left;
    content: "";
    padding-bottom: 83.3333333333%;
    margin-bottom: 0px;
  }
  .block--jobs .block__image:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
    visibility: hidden;
  }
}
.block--jobs .block__content {
  max-width: 700px;
}
.block--jobs .content-list__item {
  margin-bottom: 10px;
}

.block--team-quotes .carousel__header {
  padding-right: 0;
  padding-left: 0;
}
.block--team-quotes .carousel--active .carousel__container {
  padding-left: 0;
  padding-right: 0;
}
.block--team-quotes .carousel--active .carousel__controls {
  right: 0;
}
.block--team-quotes .carousel__title {
  margin-bottom: 40px;
  text-align: center;
}
.block--team-quotes .carousel__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--team-quotes .carousel__title {
    margin-bottom: 20px;
  }
  .block--team-quotes .carousel__title:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 62.4375em) {
  .block--benefits .page-intro {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--benefits .page-intro__title {
    text-align: left;
  }
}
.block--benefits .carousel__container {
  flex-wrap: wrap;
}
@media screen and (max-width: 62.4375em) {
  .block--benefits .carousel__container {
    flex-wrap: nowrap;
    padding-left: 0;
    scroll-snap-type: x mandatory;
  }
}
.block--benefits .benefit {
  width: 33.3333333333%;
  padding-bottom: 50px;
}
.block--benefits .benefit:nth-last-child(-n+3) {
  padding-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--benefits .benefit {
    min-width: 300px;
    width: 66vw;
    text-align: left;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    scroll-snap-align: start;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--benefits .benefit__icon {
    margin-left: 0;
    margin-bottom: 15px;
    margin-top: 0;
  }
}
@media screen and (max-width: 62.4375em) {
  .block--benefits .carousel__container.carousel__container {
    padding-bottom: 0;
  }
}

.block--timeline-event {
  margin: 100px 0;
  position: relative;
}
.block--timeline-event.block--timeline-event-last:before, .block--timeline-event.block--timeline-event-0:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 12px;
  height: 12px;
  background: #00ABCE;
  top: -50px;
  border-radius: 12px;
  transform: translateX(calc(-50% + 1px));
  z-index: -1;
  border-right: 1px solid #00ABCE;
}
.block--timeline-event.block--timeline-event-last:before {
  top: auto;
  bottom: -50px;
}
.block--timeline-event.block--timeline-event-last:after {
  bottom: -50px;
}
.block--timeline-event.block--timeline-event-0 .wrap:before {
  content: "";
  position: absolute;
  top: -50px;
  left: 50%;
  width: 1px;
  height: 50px;
  background: #00ABCE;
  z-index: -1;
}
.block--timeline-event:after {
  content: "";
  position: absolute;
  left: 50%;
  width: 1px;
  background: #00ABCE;
  top: 0;
  bottom: -200px;
  z-index: -1;
}
.block--timeline-event.block--images-1 .block__image {
  overflow: hidden;
}
.block--timeline-event.block--images-1 .block__image:before {
  float: left;
  content: "";
  padding-bottom: 60%;
  margin-bottom: 0px;
}
.block--timeline-event.block--images-1 .block__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.block--timeline-event.block--images-1 .grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(12, [col-start] 1fr);
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-column-gap: 25px;
  align-items: center;
}
@media screen and (max-width: 62.4375em) {
  .block--timeline-event.block--images-1 .grid {
    -ms-grid-rows: [col-start] minmax(min-content, 100px) [2];
    grid-template-rows: repeat(2, [col-start] minmax(min-content, 100px));
  }
}
.block--timeline-event.block--images-1 .grid > div:nth-child(2) {
  -ms-grid-column: col-start 8/span 5;
  grid-column: col-start 8/span 5;
  width: auto;
  padding: 0;
  margin-bottom: 0;
}
.block--timeline-event.block--images-1.block--align-right .grid > div:nth-child(2) .block__content {
  padding-left: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--timeline-event.block--images-1.block--align-right .grid > div:nth-child(2) {
    -ms-grid-column: col-start 1/13;
    grid-column: col-start 1/13;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2/span 1;
    margin-top: -80px;
    background-color: #FFFFFF;
    margin-left: 0px;
    margin-right: 15px;
  }
  .block--timeline-event.block--images-1.block--align-right .grid > div:nth-child(2) .block__content {
    padding: 20px;
  }
}
.block--timeline-event.block--images-1 .grid > div:nth-child(1) {
  -ms-grid-column: col-start/span 7;
  grid-column: col-start/span 7;
  width: auto;
  padding: 0;
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--timeline-event.block--images-1 .grid > div:nth-child(1) {
    -ms-grid-column: col-start 1/13;
    grid-column: col-start 1/13;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/span 1;
    margin-left: 30px;
    margin-right: 0;
  }
}
.block--timeline-event.block--images-1.block--align-left .grid > div:nth-child(2) {
  -ms-grid-column: col-start 7/span 5;
  grid-column: col-start 7/span 5;
  width: auto;
  padding: 0;
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--timeline-event.block--images-1.block--align-left .grid > div:nth-child(2) {
    -ms-grid-column: col-start 1/13;
    grid-column: col-start 1/13;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2/span 1;
    margin-top: -80px;
    background-color: #FFFFFF;
    margin-left: 15px;
  }
  .block--timeline-event.block--images-1.block--align-left .grid > div:nth-child(2) .block__content {
    padding: 20px;
  }
}
.block--timeline-event.block--images-1.block--align-left .grid > div:nth-child(1) {
  -ms-grid-column: col-start 2/span 5;
  grid-column: col-start 2/span 5;
  width: auto;
  padding: 0;
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--timeline-event.block--images-1.block--align-left .grid > div:nth-child(1) {
    -ms-grid-column: col-start 1/13;
    grid-column: col-start 1/13;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/span 1;
    margin-left: 0;
    margin-right: 30px;
  }
}
.block--timeline-event.block--images-2 .block__image--alt {
  overflow: hidden;
  max-width: 500px;
}
.block--timeline-event.block--images-2 .block__image--alt:before {
  float: left;
  content: "";
  padding-bottom: 100%;
  margin-bottom: 0px;
}
.block--timeline-event.block--images-2 .block__image--alt:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 62.4375em) {
  .block--timeline-event.block--images-2 .block__image--alt {
    max-width: none;
    width: auto;
  }
}
.block--timeline-event.block--images-2 .block__image--offset {
  overflow: hidden;
  max-width: 290px;
  transform: translate(90%, -50%);
  width: 60%;
}
.block--timeline-event.block--images-2 .block__image--offset:before {
  float: left;
  content: "";
  padding-bottom: 133.3333333333%;
  margin-bottom: 0px;
}
.block--timeline-event.block--images-2 .block__image--offset:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 62.4375em) {
  .block--timeline-event.block--images-2 .block__image--offset {
    display: none;
  }
}
.block--timeline-event.block--images-2 .grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(12, [col-start] 1fr);
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-column-gap: 20px;
  align-items: center;
}
@media screen and (max-width: 62.4375em) {
  .block--timeline-event.block--images-2 .grid {
    grid-column-gap: 25px;
    -ms-grid-rows: [col-start] minmax(min-content, 100px) [2];
    grid-template-rows: repeat(2, [col-start] minmax(min-content, 100px));
  }
}
.block--timeline-event.block--images-2 .grid > div:nth-child(2) {
  -ms-grid-column: col-start 8/span 5;
  grid-column: col-start 8/span 5;
  width: auto;
  padding: 0;
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--timeline-event.block--images-2 .grid > div:nth-child(2) {
    -ms-grid-column: col-start 1/13;
    grid-column: col-start 1/13;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2/span 1;
    z-index: 1;
    margin-top: -80px;
    background-color: #FFFFFF;
    margin-left: 0px;
    margin-right: 15px;
  }
  .block--timeline-event.block--images-2 .grid > div:nth-child(2) .block__content {
    padding: 20px;
    text-align: right;
  }
}
.block--timeline-event.block--images-2 .grid > div:nth-child(1) {
  -ms-grid-column: col-start/span 7;
  grid-column: col-start/span 7;
  width: auto;
  padding: 0;
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--timeline-event.block--images-2 .grid > div:nth-child(1) {
    -ms-grid-column: col-start 1/13;
    grid-column: col-start 1/13;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/span 1;
    margin-left: 30px;
    margin-right: 0;
  }
}
.block--timeline-event.block--images-2.block--align-right .block__image--alt {
  margin-left: auto;
}
.block--timeline-event.block--images-2.block--align-right .block__image--offset {
  transform: translate(-50px, -50%);
  right: auto;
  left: 0;
}
.block--timeline-event.block--images-2.block--align-left .grid > div:nth-child(2) {
  -ms-grid-column: col-start 8/span 5;
  grid-column: col-start 8/span 5;
  width: auto;
  padding: 0;
  margin-bottom: 0;
}
.block--timeline-event.block--images-2.block--align-left .grid > div:nth-child(2) .block__content {
  padding-right: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--timeline-event.block--images-2.block--align-left .grid > div:nth-child(2) {
    -ms-grid-column: col-start 1/13;
    grid-column: col-start 1/13;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2/span 1;
    margin-top: -80px;
    background-color: #FFFFFF;
    margin-left: 15px;
    margin-right: 0;
  }
  .block--timeline-event.block--images-2.block--align-left .grid > div:nth-child(2) .block__content {
    padding: 20px;
    text-align: left;
  }
}
.block--timeline-event.block--images-2.block--align-left .grid > div:nth-child(1) {
  -ms-grid-column: col-start 1/span 5;
  grid-column: col-start 1/span 5;
  width: auto;
  padding: 0;
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .block--timeline-event.block--images-2.block--align-left .grid > div:nth-child(1) {
    -ms-grid-column: col-start 1/13;
    grid-column: col-start 1/13;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/span 1;
    margin-left: 0;
  }
}
.block--timeline-event .block__title {
  font-size: 90px;
  font-size: 5.625rem;
  line-height: 1.1111111111;
  font-weight: 600;
  display: inline-block;
  border-bottom: 5px solid #00ABCE;
  margin-bottom: 20px;
}
@media screen and (max-width: 62.4375em) {
  .block--timeline-event .block__title {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1.1666666667;
  }
}
.block--timeline-event .block__text {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.8333333333;
  font-weight: 300;
}
.block--timeline-event .block__content--twin .block__text {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.8333333333;
  font-weight: 300;
}
.block--timeline-event .block__image--client {
  opacity: 0.4;
  margin-top: 30px;
}
.block--timeline-event .block__image--client img {
  max-height: 150px;
  width: auto;
}
.block--timeline-event .block__image--client:before, .block--timeline-event .block__image--client:after {
  display: none;
}
.block--timeline-event .grid--reverse .block__content {
  text-align: right;
}
.block--timeline-event .grid--reverse .block__title {
  text-align: right;
}

.block--form:last-child {
  margin-bottom: -160px;
}
@media screen and (max-width: 62.4375em) {
  .block--form:last-child {
    margin-bottom: -80px;
  }
}
.blocks--tight .block--form:last-child {
  margin-bottom: 90px;
}

@media screen and (max-width: 62.4375em) {
  .block--callouts {
    margin-top: 50px;
  }
}

.carousel.carousel--active {
  overflow: hidden;
}

.carousel--quotes {
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 62.4375em) {
  .carousel--quotes {
    overflow: hidden;
  }
}

.carousel__header {
  position: relative;
  margin-right: 80px;
  margin-left: 80px;
  padding-right: 80px;
}
.carousel--quotes .carousel__header {
  position: static;
}
@media screen and (min-width: 88.75em) {
  .carousel__header {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media screen and (max-width: 62.4375em) {
  .carousel__header {
    margin-right: 16px;
    margin-left: 16px;
  }
}
@media screen and (max-width: 42.5em) {
  .carousel__header {
    padding-right: 0;
  }
}
[data-carousel-activate=l] .carousel__header {
  display: none;
  display: block;
}
[data-carousel-activate=ml] .carousel__header {
  display: none;
}
@media screen and (max-width: 75em) {
  [data-carousel-activate=ml] .carousel__header {
    display: block;
  }
}
[data-carousel-activate=n] .carousel__header {
  display: none;
}
@media screen and (max-width: 62.4375em) {
  [data-carousel-activate=n] .carousel__header {
    display: block;
  }
}
[data-carousel-activate=m] .carousel__header {
  display: none;
}
@media screen and (max-width: 62.4375em) {
  [data-carousel-activate=m] .carousel__header {
    display: block;
  }
}
[data-carousel-activate=ph] .carousel__header {
  display: none;
}
@media screen and (max-width: 42.5em) {
  [data-carousel-activate=ph] .carousel__header {
    display: block;
  }
}
[data-carousel-activate=s] .carousel__header {
  display: none;
}
@media screen and (max-width: 34.375em) {
  [data-carousel-activate=s] .carousel__header {
    display: block;
  }
}

@media screen and (max-width: 62.4375em) {
  .carousel__header--mobile-center {
    text-align: center;
  }
}

.carousel__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.32;
  font-weight: 500;
  margin-bottom: 18px;
}
.carousel__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .carousel__title {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.4285714286;
    margin-bottom: 18px;
    letter-spacing: 0.4px;
  }
  .carousel__title:last-child {
    margin-bottom: 0;
  }
}

.carousel__intro {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -8px;
}
.carousel__intro:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 42.5em) {
  .carousel__intro {
    margin-bottom: 20px;
    display: block;
    margin-top: -9px;
  }
  .carousel__intro:last-child {
    margin-bottom: 0;
  }
}
.carousel__intro .cta {
  transform: translateY(3px);
}

.carousel__text {
  margin-right: 15px;
}
.carousel__text:last-child {
  margin-right: 0;
}
@media screen and (max-width: 42.5em) {
  .carousel__text {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .carousel__text:last-child {
    margin-bottom: 0;
  }
}

.carousel__controls {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (min-width: 88.75em) {
  .carousel__controls {
    right: 80px;
  }
}
@media screen and (max-width: 42.5em) {
  .carousel__controls {
    display: none;
  }
}
@media screen and (max-width: 42.5em) {
  .carousel--show-controls .carousel__controls {
    display: flex;
  }
}
.carousel__intro + .carousel__controls {
  top: auto;
}
.carousel__intro--has-cta + .carousel__controls {
  bottom: -17px;
}
.carousel--quotes .carousel__controls {
  left: 0;
  bottom: 30px;
  top: auto;
  justify-content: center;
  z-index: 1;
}
.carousel__controls:first-child:last-child {
  position: static;
  margin-right: -80px;
  margin-bottom: 18px;
}

.carousel__left,
.carousel__right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0;
  background: transparent;
  border: none;
}
.carousel__left[disabled],
.carousel__right[disabled] {
  opacity: 0.3;
  pointer-events: none;
}
.carousel--quotes .carousel__left svg,
.carousel--quotes .carousel__right svg {
  fill: #FFFFFF;
}
.carousel__left svg,
.carousel__right svg {
  transition: fill 0.3s;
}
.carousel__left:hover svg, .carousel__left:focus svg,
.carousel__right:hover svg,
.carousel__right:focus svg {
  fill: #00ABCE;
}

.carousel__left {
  margin-right: 10px;
}
.carousel--quotes .carousel__left {
  margin-right: 0;
}
.carousel__left svg {
  transform: scaleX(-1);
}

.carousel__container {
  /* Hide scrollbar for IE, Edge add Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  margin-bottom: 20px;
  display: flex;
  overflow: auto;
}
.carousel__container::-webkit-scrollbar {
  display: none;
}
.carousel__container:last-child {
  margin-bottom: 0;
}
.carousel--active .carousel__container {
  padding-left: 80px;
  padding-bottom: 0;
  padding-top: 2px;
}
@media screen and (max-width: 62.4375em) {
  .carousel--active .carousel__container {
    margin-bottom: 10px;
    padding-left: 0;
  }
  .carousel--active .carousel__container:last-child {
    margin-bottom: 0;
  }
}
.carousel--quotes .carousel--active .carousel__container {
  position: relative;
}
@media screen and (max-width: 62.4375em) {
  .carousel--reset .carousel--active .carousel__container {
    padding-right: 16px;
  }
}
.carousel--quotes .carousel__container, .carousel--site-links .carousel__container, .carousel--callouts .carousel__container {
  flex-wrap: wrap;
  margin-left: -25px;
  padding-bottom: 2px;
}
@media screen and (max-width: 62.4375em) {
  .carousel--quotes .carousel__container, .carousel--site-links .carousel__container, .carousel--callouts .carousel__container {
    margin-bottom: 10px;
    flex-wrap: nowrap;
    overflow: auto;
    margin-right: 0;
    margin-left: -16px;
    padding-left: 16px;
  }
  .carousel--quotes .carousel__container:last-child, .carousel--site-links .carousel__container:last-child, .carousel--callouts .carousel__container:last-child {
    margin-bottom: 0;
  }
}
.carousel--quotes .carousel__container, .carousel--callouts .carousel__container {
  margin-left: 0;
  flex-wrap: nowrap;
  justify-content: space-between;
}
@media screen and (max-width: 62.4375em) {
  .carousel--quotes .carousel__container, .carousel--callouts .carousel__container {
    margin-left: -16px;
  }
}
@media screen and (max-width: 62.4375em) {
  .carousel--quotes .carousel__container {
    padding-left: 0;
    margin-left: 0;
  }
}
.carousel--active:not(.carousel--quotes)[data-carousel-activate=l] .carousel__container > * {
  width: calc(74vw - 16px);
  min-width: calc(74vw - 16px);
}
@media screen and (max-width: 75em) {
  .carousel--active:not(.carousel--quotes)[data-carousel-activate=ml] .carousel__container > * {
    width: calc(74vw - 16px);
    min-width: calc(74vw - 16px);
  }
}
@media screen and (max-width: 62.4375em) {
  .carousel--active:not(.carousel--quotes)[data-carousel-activate=n] .carousel__container > * {
    width: calc(74vw - 16px);
    min-width: calc(74vw - 16px);
  }
}
@media screen and (max-width: 62.4375em) {
  .carousel--active:not(.carousel--quotes)[data-carousel-activate=m] .carousel__container > * {
    width: calc(74vw - 16px);
    min-width: calc(74vw - 16px);
  }
}
@media screen and (max-width: 42.5em) {
  .carousel--active:not(.carousel--quotes)[data-carousel-activate=ph] .carousel__container > * {
    width: calc(74vw - 16px);
    min-width: calc(74vw - 16px);
  }
}
@media screen and (max-width: 34.375em) {
  .carousel--active:not(.carousel--quotes)[data-carousel-activate=s] .carousel__container > * {
    width: calc(74vw - 16px);
    min-width: calc(74vw - 16px);
  }
}
.carousel--active:not(.carousel--quotes) .carousel__container > * *:focus {
  outline: none;
}
@media screen and (min-width: 88.75em) {
  .carousel--active:not(.carousel--quotes) .carousel__container > *:first-child {
    margin-left: calc(0.5 * (100vw - (1400px - 2 * 80px)) - 80px);
  }
}
@media screen and (max-width: 88.6875em) {
  .carousel--active:not(.carousel--quotes) .carousel__container > *:first-child {
    margin-left: 0;
  }
}
@media screen and (max-width: 62.4375em) {
  .carousel--active:not(.carousel--quotes) .carousel__container > *:first-child {
    margin-left: 16px;
  }
}
@media screen and (min-width: 88.75em) {
  .carousel--active:not(.carousel--quotes) .carousel__container > *:last-child {
    margin-right: calc(0.5 * (100vw - (1400px - 2 * 80px)));
  }
}
@media screen and (max-width: 88.6875em) {
  .carousel--active:not(.carousel--quotes) .carousel__container > *:last-child {
    margin-right: 80px;
  }
}
@media screen and (max-width: 62.4375em) {
  .carousel--active:not(.carousel--quotes) .carousel__container > *:last-child {
    margin-right: 16px;
  }
}
.block:last-child .carousel__container {
  padding-bottom: 0;
}

.carousel-item {
  min-width: 40%;
  padding-left: 20px;
}
@media screen and (max-width: 62.4375em) {
  .carousel-item {
    min-width: 80%;
  }
}
.carousel-item__image {
  margin-bottom: 35px;
}
.carousel-item__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
}

.carousel .team-card {
  margin-right: 22px;
}
@media screen and (max-width: 42.5em) {
  .carousel .team-card {
    flex: 0 0 auto;
    width: calc(53.85vw - 16px);
    margin-right: 16px;
  }
}
.carousel .team-card:last-child {
  margin-right: 80px;
}
@media screen and (max-width: 42.5em) {
  .carousel .team-card:last-child {
    margin-right: 16px;
  }
}

.carousel .team-card__wrapper {
  width: 270px;
}
@media screen and (max-width: 42.5em) {
  .carousel .team-card__wrapper {
    width: auto;
  }
}

.team-card__image {
  overflow: hidden;
  margin-bottom: 13px;
}
.team-card__image:before {
  float: left;
  content: "";
  padding-bottom: 122.5925925926%;
  margin-bottom: 0px;
}
.team-card__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.team-card__image:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .team-card__image {
    margin-bottom: 10px;
  }
  .team-card__image:last-child {
    margin-bottom: 0;
  }
}
.team-card__image img {
  filter: grayscale(1);
  width: 100%;
  height: auto;
}

.team-card__title {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 2;
  letter-spacing: 0.3px;
}

.carousel .quote-card {
  max-width: 33.3333333333%;
  flex-basis: 33.3333333333%;
}
@media screen and (max-width: 42.5em) {
  .carousel .quote-card {
    max-width: calc(100% - 4 * 16px);
    flex-basis: calc(100% - 4 * 16px);
  }
}
.carousel .quote-card[data-carousel-item-viewing=true] img {
  transition: 0.5s filter;
  filter: none;
}

.quote-card__wrapper {
  width: 533.3333333333px;
  max-width: 100%;
  position: relative;
}
.quote-card__image {
  overflow: hidden;
  position: relative;
}
.quote-card__image:before {
  float: left;
  content: "";
  padding-bottom: 120%;
  margin-bottom: 0px;
}
.quote-card__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.quote-card__image img {
  transition: 0.1s filter, 0.2s transform;
  transform: scale(110%);
  filter: grayscale(1) blur(5px);
  width: 100%;
  height: auto;
}
.quote-card__image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.4) 60%);
  height: 100%;
  visibility: visible;
}
@media screen and (max-width: 42.5em) {
  .quote-card__image:after {
    display: none;
  }
}

.quote-card__copy {
  display: none;
  opacity: 0;
  transition: 0.3s opacity;
  position: absolute;
  bottom: 15%;
  text-align: center;
  color: #FFFFFF;
  left: -100px;
  right: -100px;
  z-index: 1;
}
@media screen and (max-width: 42.5em) {
  .quote-card__copy {
    left: 16px;
    right: 16px;
    padding-top: 25px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    bottom: auto;
    color: #000000;
    left: auto;
    right: auto;
  }
}
.carousel--scrolling .quote-card__copy {
  display: block;
  opacity: 0;
}
.carousel:not(.carousel--scrolling) [data-carousel-item-active=true] .quote-card__copy {
  display: block;
}
.carousel:not(.carousel--scrolling) [data-carousel-item-viewing=true] .quote-card__copy {
  opacity: 1;
}

.quote-card__quote {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.2;
}
@media screen and (max-width: 62.4375em) {
  .quote-card__quote {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.3333333333;
  }
}
@media screen and (max-width: 42.5em) {
  .quote-card__quote {
    letter-spacing: 0.4px;
  }
}

.quote-card__person {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.6666666667;
  letter-spacing: 0.3px;
}
@media screen and (max-width: 62.4375em) {
  .quote-card__person {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1.4705882353;
  }
}
@media screen and (max-width: 42.5em) {
  .quote-card__person {
    letter-spacing: 0.5px;
    padding-top: 20px;
  }
}

.principle {
  position: relative;
  padding-left: 96px;
}
@media screen and (max-width: 62.4375em) {
  .principle {
    padding-left: 0;
    text-align: center;
  }
}

.principle__image {
  overflow: hidden;
  position: absolute;
  top: 9px;
  left: 0;
  width: 56px;
}
.principle__image:before {
  float: left;
  content: "";
  padding-bottom: 100%;
  margin-bottom: 0px;
}
.principle__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 62.4375em) {
  .principle__image {
    margin-bottom: 3px;
    position: static;
    margin-right: auto;
    margin-left: auto;
  }
  .principle__image:last-child {
    margin-bottom: 0;
  }
}
.principle__image svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.principle__title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.6666666667;
  font-weight: 500;
  margin-bottom: 3px;
}
.principle__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .principle__title {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1.7647058824;
  }
}

.principle__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
}
@media screen and (max-width: 62.4375em) {
  .principle__text {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.7333333333;
  }
}

.carousel .callout {
  margin-right: 26px;
  flex-basis: calc(33.33% - 18px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (max-width: 62.4375em) {
  .carousel .callout {
    flex: 0 0 auto;
    width: calc(72vw - 16px);
    margin-right: 16px;
  }
}
.carousel .callout:last-child {
  margin-right: 0;
}
@media screen and (max-width: 62.4375em) {
  .carousel .callout:last-child {
    margin-right: 16px;
  }
}
.carousel .callout a {
  margin-top: auto;
}

.callout__image {
  overflow: hidden;
  margin-bottom: 24px;
}
.callout__image:before {
  float: left;
  content: "";
  padding-bottom: 73.2323232323%;
  margin-bottom: 0px;
}
.callout__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.callout__image:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .callout__image {
    overflow: hidden;
    margin-bottom: 20px;
  }
  .callout__image:before {
    float: left;
    content: "";
    padding-bottom: 60.2339181287%;
    margin-bottom: 0px;
  }
  .callout__image:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
    visibility: hidden;
  }
  .callout__image:last-child {
    margin-bottom: 0;
  }
}
.callout__image img {
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: transform 0.5s ease;
}
.callout__image:hover img {
  transform: scale(1.04);
}

.callout__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 1.32;
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: 0.8px;
}
.callout__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .callout__title {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.3333333333;
    letter-spacing: 0.6px;
  }
}

.callout__text {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.4444444444;
  margin-bottom: 10px;
}
.callout__text:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .callout__text {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1.4705882353;
    margin-bottom: 16px;
  }
  .callout__text:last-child {
    margin-bottom: 0;
  }
}

.carousel .project-card {
  margin-right: 22px;
}
@media screen and (max-width: 42.5em) {
  .carousel .project-card {
    flex: 0 0 auto;
    width: calc(70vw - 16px);
    margin-right: 16px;
  }
}
.carousel .project-card:last-child {
  margin-right: 80px;
}
@media screen and (max-width: 42.5em) {
  .carousel .project-card:last-child {
    margin-right: 16px;
  }
}

.project-card__link {
  display: block;
  text-decoration: none;
  color: inherit;
}
.carousel .project-card__link {
  width: 396px;
}
@media screen and (max-width: 42.5em) {
  .carousel .project-card__link {
    width: auto;
  }
}

.project-card__image {
  overflow: hidden;
  margin-bottom: 24px;
}
.project-card__image:before {
  float: left;
  content: "";
  padding-bottom: 60.101010101%;
  margin-bottom: 0px;
}
.project-card__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.project-card__image:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .project-card__image {
    margin-bottom: 10px;
  }
  .project-card__image:last-child {
    margin-bottom: 0;
  }
}
.project-card__image img {
  width: 100%;
  height: auto;
}

.project-card__title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: 0.3px;
}
.project-card__title:last-child {
  margin-bottom: 0;
}
.project-card__link:hover .project-card__title {
  text-decoration: underline;
}

.project-card__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
}

.carousel .gallery-card {
  margin-right: 22px;
}
@media screen and (max-width: 42.5em) {
  .carousel .gallery-card {
    flex: 0 0 auto;
    width: calc(70vw - 16px);
    margin-right: 16px;
  }
}
.carousel .gallery-card:last-child {
  margin-right: 80px;
}
@media screen and (max-width: 42.5em) {
  .carousel .gallery-card:last-child {
    margin-right: 16px;
  }
}

.gallery-card__link {
  display: block;
  text-decoration: none;
  color: inherit;
  overflow: hidden;
}
.carousel .gallery-card__link {
  width: 712px;
  min-height: 712px;
}
@media screen and (max-width: 42.5em) {
  .carousel .gallery-card__link {
    width: auto;
  }
}

.gallery-card__background {
  padding: 65px 65px 0;
}

.gallery-card__image {
  overflow: hidden;
  margin-bottom: 24px;
}
.gallery-card__image:before {
  float: left;
  content: "";
  padding-bottom: 111.1111111111%;
  margin-bottom: 0px;
}
.gallery-card__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.gallery-card__image:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .gallery-card__image {
    margin-bottom: 10px;
  }
  .gallery-card__image:last-child {
    margin-bottom: 0;
  }
}
.gallery-card__image img {
  width: 100%;
  height: auto;
}

.gallery-card__title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: 0.3px;
}
.gallery-card__title:last-child {
  margin-bottom: 0;
}

.gallery-card__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
}

.gallery-device {
  display: flex;
}
.carousel .gallery-device {
  margin-right: 22px;
}
@media screen and (max-width: 42.5em) {
  .carousel .gallery-device {
    flex: 0 0 auto;
    width: calc(70vw - 16px);
    margin-right: 16px;
  }
}
.carousel .gallery-device:last-child {
  margin-right: 80px;
}
@media screen and (max-width: 42.5em) {
  .carousel .gallery-device:last-child {
    margin-right: 16px;
  }
}

.gallery-device__link {
  display: block;
  text-decoration: none;
  color: inherit;
  overflow: hidden;
  display: flex;
  position: relative;
}
.carousel .gallery-device__link {
  width: 712px;
}
@media screen and (max-width: 42.5em) {
  .carousel .gallery-device__link {
    width: auto;
  }
}

.gallery-device__background {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.gallery-device__mag {
  position: absolute;
  top: 14px;
  left: 14px;
}
.gallery-device__mag svg {
  fill: #FFFFFF;
  width: 25px;
  height: 25px;
}

.gallery-device__image {
  width: 458px;
  outline: 2px solid black;
}
.gallery-device__device--tablet + .gallery-device__image {
  width: 300px;
  margin-top: 29px;
}
.gallery-device__device--desktop + .gallery-device__image {
  width: 494px;
  margin-top: -69px;
}

.gallery-device__device {
  position: absolute;
}

.feature--full {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}
@media screen and (max-width: 34.375em) {
  .feature--full {
    display: block;
  }
}

.feature__link {
  color: #000000;
  text-decoration: none;
}

.feature__image {
  overflow: hidden;
  margin-bottom: 30px;
  width: 315px;
  margin-right: auto;
  margin-left: auto;
}
.feature__image:before {
  float: left;
  content: "";
  padding-bottom: 44.4444444444%;
  margin-bottom: 0px;
}
.feature__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.feature__image:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .feature__image {
    margin-bottom: 10px;
  }
  .feature__image:last-child {
    margin-bottom: 0;
  }
}
.feature--full .feature__image {
  margin-right: 65px;
  flex: 0 0 auto;
  margin-bottom: 0;
}
.feature--full .feature__image:last-child {
  margin-right: 0;
}
@media screen and (max-width: 62.4375em) {
  .feature--full .feature__image {
    margin-right: 10px;
    margin-left: auto;
  }
}
@media screen and (max-width: 34.375em) {
  .feature--full .feature__image {
    margin-bottom: 15px;
    width: auto;
    margin-right: 0;
  }
  .feature--full .feature__image:last-child {
    margin-bottom: 0;
  }
}

.feature__content {
  max-width: 280px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
@media screen and (max-width: 62.4375em) {
  .feature__content {
    max-width: 310px;
  }
}
.feature--full .feature__content {
  max-width: none;
  text-align: left;
}
@media screen and (max-width: 62.4375em) {
  .feature--full .feature__content {
    text-align: center;
    margin-right: auto;
    margin-left: 10px;
  }
}
@media screen and (max-width: 34.375em) {
  .feature--full .feature__content {
    max-width: none;
    margin-left: 0;
  }
}

.feature__title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.6666666667;
  font-weight: 500;
  margin-bottom: 3px;
}
.feature__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .feature__title {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1.7647058824;
    margin-bottom: 7px;
    letter-spacing: 0.4px;
    text-align: left;
  }
  .feature__title:last-child {
    margin-bottom: 0;
  }
}
.feature--full .feature__title {
  margin-bottom: 7px;
}
.feature--full .feature__title:last-child {
  margin-bottom: 0;
}
.feature__link:hover .feature__title {
  text-decoration: underline;
}

.feature__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  margin-bottom: 20px;
}
.feature__text:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .feature__text {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.7333333333;
    margin-bottom: 13px;
    text-align: left;
  }
  .feature__text:last-child {
    margin-bottom: 0;
  }
}
.feature--full .feature__text {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7142857143;
}
@media screen and (max-width: 62.4375em) {
  .feature--full .feature__text {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.7333333333;
  }
}

.feature__date {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 300;
  letter-spacing: 0.6px;
}

.gallery {
  display: flex;
  flex-direction: column;
}

.gallery--mobile {
  padding-top: 0;
  color: #FFFFFF;
  background: #000000;
}

.gallery--carousel {
  background: transparent;
  color: #000000;
}

.gallery__header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 62.4375em) {
  .gallery__header {
    padding-right: 16px;
    padding-left: 16px;
  }
}
.gallery--black .gallery__header {
  background: #000000;
  color: #FFFFFF;
}

.gallery.gallery--black .gallery__close svg, .gallery.gallery--mobile .gallery__close svg {
  stroke: #FFFFFF;
}
@media screen and (max-width: 62.4375em) {
  .gallery .gallery__close svg {
    stroke: #FFFFFF;
  }
}

.gallery__alt {
  position: absolute;
  left: 20px;
  cursor: pointer;
}
@media screen and (max-width: 62.4375em) {
  .gallery .gallery__alt {
    display: none;
  }
}

.gallery__nav {
  padding: 10px;
  background: transparent;
  border: none;
}
.gallery__nav[data-action=previous] {
  transform: scaleX(-1);
}
.gallery__nav svg {
  display: block;
  fill: currentColor;
}
.gallery--carousel .gallery__nav[data-action=next] {
  margin-left: 20px;
}
@media screen and (max-width: 34.375em) {
  .gallery--carousel .gallery__nav[data-action=next] {
    margin-left: 10px;
  }
}
.gallery--carousel .gallery__nav[data-action=previous] {
  margin-right: 20px;
}
@media screen and (max-width: 34.375em) {
  .gallery--carousel .gallery__nav[data-action=previous] {
    margin-right: 10px;
  }
}

.gallery__nav--disabled {
  opacity: 0.5;
}

.gallery__title {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 2.1428571429;
  font-weight: 500;
  margin: 0 25px;
  letter-spacing: 0.4px;
  text-align: center;
  text-transform: uppercase;
}

.gallery__image {
  height: calc(100vh - 61px);
  overflow: auto;
  background: #FFFFFF;
}
.gallery__image img {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.gallery__content {
  flex: 1 1 auto;
  display: block;
  position: relative;
  background-color: #FFFFFF;
}
.gallery--mobile .gallery__content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}
@media screen and (max-width: 34.375em) {
  .gallery--mobile .gallery__content {
    background-color: #000000;
    padding: 0;
  }
}
@media screen and (max-width: 34.375em) {
  .gallery--carousel .gallery__content {
    background-color: transparent;
  }
}
.gallery--black .gallery__content {
  background-color: #000000;
}

.gallery__swiper {
  position: relative;
  width: 100%;
}

.gallery__style {
  display: flex;
  height: 100%;
}
.gallery--mobile .gallery__style {
  border-radius: 30px;
  background-color: #000000;
  max-width: 320px;
  width: 100%;
  position: relative;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
  padding: 40px 10px 60px;
  max-height: none;
}
@media screen and (max-width: 42.5em) {
  .gallery--mobile .gallery__style {
    padding: 40px 10px 60px;
  }
}
@media screen and (max-width: 34.375em) {
  .gallery--mobile .gallery__style {
    border-radius: 20px;
    padding: 30px 8px 40px;
  }
}
.overlay--gallery .gallery--mobile .gallery__style {
  max-height: 700px;
}
@media screen and (max-width: 42.5em) {
  .overlay--gallery .gallery--mobile .gallery__style {
    max-height: 100%;
    max-width: none;
    border-radius: 0;
    padding: 0;
  }
}
.gallery--carousel .gallery__style {
  width: calc(100% - 110px);
}
@media screen and (max-width: 34.375em) {
  .gallery--carousel .gallery__style {
    width: calc(100% - 90px);
  }
}

.browser {
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px 5px 0 0;
}

.browser__bar {
  background-color: #3A3B3D;
  border-radius: 5px 5px 0 0;
  height: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.browser__dot {
  background-color: #4D4D4D;
  border-radius: 6px;
  width: 6px;
  height: 6px;
  margin-top: -3px;
  position: absolute;
  top: 50%;
  left: 8px;
  transition: background-color 0.3s ease;
}
.browser:hover .browser__dot {
  background-color: #FF5B52;
}
.browser__dot + .browser__dot {
  left: 17px;
  transition-delay: 0.05s;
}
.browser:hover .browser__dot + .browser__dot {
  background-color: #E5C027;
}
.browser__dot + .browser__dot + .browser__dot {
  left: 26px;
  transition-delay: 0.1s;
}
.browser:hover .browser__dot + .browser__dot + .browser__dot {
  background-color: #53C22B;
}

.browser__address {
  background-color: #4D4D4D;
  font-size: 6px;
  font-size: 0.375rem;
  line-height: 10px;
  max-width: 200px;
  width: 100%;
  height: 10px;
  text-align: center;
  border-radius: 1px;
  color: #999999;
  transition: color 0.3s ease;
}
.browser:hover .browser__address {
  color: #DDDDDD;
}

/* basic */
.swiper-container {
  position: relative;
  overflow: hidden;
}
.overlay--gallery .swiper-container {
  position: absolute;
}

.swiper-container,
.swiper-wrapper {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.swiper-wrapper {
  width: max-content;
}

.swiper-slide {
  position: relative;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  align-items: flex-start;
  cursor: grab;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: auto;
  min-height: 100%;
  max-width: 1400px;
}
@media screen and (max-width: 62.4375em) {
  .gallery--mobile .swiper-slide img {
    max-width: 100%;
  }
}

/* image lazyload */
.swiper-lazy {
  display: block;
  width: 100%;
  min-height: 100%;
  opacity: 0;
  transition: all ease 300ms;
}

.swiper-lazy-preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.swiper-lazy-loaded {
  opacity: 1;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(0, 0, 0, 0.88);
  border-radius: 50%;
  border-bottom-color: transparent;
  transform-origin: 50%;
  animation: rotate 1s infinite linear;
}
.gallery--mobile .swiper-lazy-preloader:after, .gallery--black .swiper-lazy-preloader:after {
  border-color: rgba(255, 255, 255, 0.88);
  border-bottom-color: transparent;
}

@keyframes rotate {
  0% {
    transform: rotate3d(0, 0, 0, 0);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}
.service {
  width: calc(25% - 50px);
  margin: 27px 25px;
  padding-top: 10px;
  border-top: 1px solid #000000;
}
.block__services .service:last-child {
  display: none;
}
@media screen and (max-width: 42.5em) {
  .block__services .service:last-child {
    display: block;
  }
}
@media screen and (max-width: 62.4375em) {
  .service {
    margin: 27px 10px;
    width: calc(50% - 20px);
  }
}
@media screen and (max-width: 42.5em) {
  .service {
    margin-bottom: 20px;
    width: 100%;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    padding-top: 0;
    padding-right: 20px;
    border-top: none;
  }
  .service:last-child {
    margin-bottom: 0;
  }
  .carousel--active .service {
    margin-bottom: 0;
  }
}
.service--full {
  width: 100%;
  text-align: center;
  border-top: none;
  margin-top: 90px;
  margin-bottom: 130px;
  margin-left: 0;
  margin-right: 0;
}
.service--full .service__title {
  display: block;
  margin-bottom: 10px;
}
@media screen and (max-width: 42.5em) {
  .service--full .service__title {
    margin-top: 0;
    display: flex;
  }
}
@media screen and (max-width: 42.5em) {
  .service--full {
    margin-top: 20px;
    text-align: left;
  }
}

.service__link {
  display: block;
  color: inherit;
  text-decoration: none;
}

.service__title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.6666666667;
  font-weight: 500;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}
.service__title:last-child {
  margin-bottom: 0;
}
.service__title svg {
  margin-left: 13px;
  transition: transform 0.2s ease;
}
.service__link:hover .service__title svg {
  transform: translateX(5px);
  fill: #00ABCE;
}
@media screen and (max-width: 62.4375em) {
  .service__link:hover .service__title svg {
    fill: inherit;
  }
}
.service__link:hover .service__title {
  color: #00ABCE;
}
@media screen and (max-width: 62.4375em) {
  .service__link:hover .service__title {
    color: inherit;
  }
}

.service__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
}

.approach {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
@media screen and (max-width: 42.5em) {
  .approach {
    text-align: left;
    padding-right: 20px;
    padding-left: 0;
  }
}

.approach--grid {
  display: flex;
  text-align: left;
}
@media screen and (max-width: 42.5em) {
  .approach--grid {
    flex-direction: column;
    align-items: flex-start;
  }
}

.approach__image {
  margin-bottom: 20px;
  display: flex;
  width: 56px;
  max-height: 62px;
  margin-right: auto;
  margin-left: auto;
}
.approach__image:last-child {
  margin-bottom: 0;
}
.approach__image svg {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 42.5em) {
  .approach__image {
    margin-left: 0;
  }
}
.approach--grid .approach__image {
  margin-left: 0;
  margin-right: 40px;
  margin-top: 6px;
  flex-shrink: 0;
}
@media screen and (max-width: 42.5em) {
  .approach--grid .approach__image {
    margin: 0 0 20px;
  }
}

.approach__title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.6666666667;
  font-weight: 500;
  margin-bottom: 3px;
}
.approach__title:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 62.4375em) {
  .approach__title {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1.7647058824;
  }
}

.approach__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
}
@media screen and (max-width: 62.4375em) {
  .approach__text {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.7333333333;
  }
}

.select-replace {
  position: relative;
  display: flex;
  flex-direction: column;
}
.select-replace:before {
  content: attr(data-value);
  height: 30px;
  padding: 4px 0;
  border-bottom: 1px solid #979797;
  pointer-events: none;
  order: 1;
}
.select-replace:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 15px;
  width: 7px;
  height: 7px;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
  transform: translateX(-12px) rotate(45deg) skewX(10deg) skewY(10deg);
}
.select-replace.field-error:before {
  border-color: #B53638;
}
.select-replace.field-error:after {
  bottom: 35px;
}
.select-replace label {
  order: 0;
}
.select-replace select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.select-replace option {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.select-replace p.error {
  order: 4;
}

.select-replace--minimal {
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}
.block--fit .select-replace--minimal:after {
  display: none;
  margin-right: 10px;
}
@media screen and (max-width: 62.4375em) {
  .block--fit .select-replace--minimal:after {
    margin-right: 0;
  }
}
.select-replace--minimal:before {
  height: auto;
  padding: 0 8px 0 0;
  border-bottom: none;
}

html .overlay {
  z-index: 25;
}

.overlay--gallery .overlay__container {
  top: 0;
  left: 0;
  max-width: none;
  height: 100%;
  transform: none;
  box-shadow: none;
}
.overlay--gallery .overlay__container > .overlay__close {
  display: none;
}
.overlay--gallery .overlay__bg {
  background-color: #FFFFFF;
}
.overlay--gallery .overlay__close {
  top: 11px;
  right: 10px;
  padding: 10px;
  background: transparent;
  border: none;
  z-index: 1;
}
.overlay--gallery .overlay__close svg {
  width: 20px;
  height: 20px;
  stroke: #000000;
}
.overlay--gallery .overlay__content {
  min-height: 100vh;
  width: 100%;
}
.overlay--gallery .gallery {
  height: 100%;
  width: 100%;
  position: absolute;
}
.body--admin .overlay--gallery .gallery {
  padding-top: 44px;
}
.body--debug .overlay--gallery .gallery {
  padding-top: 20px;
}
@media screen and (max-width: 62.4375em) {
  .body--debug .overlay--gallery .gallery {
    padding-top: 0;
  }
}
.body--admin.body--debug .overlay--gallery .gallery {
  padding-top: 64px;
}
@media screen and (max-width: 62.4375em) {
  .body--admin.body--debug .overlay--gallery .gallery {
    padding-top: 44px;
  }
}
.overlay--gallery .gallery.gallery--black {
  background-color: #000000;
}