.team-card {

    .carousel & {
        margin-right: 22px;

        @include breakpoint(phablet) {
            flex: 0 0 auto;
            width: calc(53.85vw - #{$site-gutter--mobile});
            margin-right: $site-gutter--mobile;
        }

        &:last-child {
            margin-right: $site-gutter;

            @include breakpoint(phablet) {
                margin-right: $site-gutter--mobile;
            }
        }
    }
}

    .team-card__wrapper {

        .carousel & {
            width: 270px;

            @include breakpoint(phablet) {
                width: auto;
            }
        }
    }

        .team-card__image {
            @include aspect(270 / 331);
            @include margin(bottom, 13px);

            @include breakpoint(tablet) {
                @include margin(bottom, 10px);
            }

            img {
                filter: grayscale(1);
                width: 100%;
                height: auto;
            }
        }

        .team-card__title {
            @include font(15px, 30px);

            letter-spacing: 0.3px;
        }
